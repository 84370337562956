import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  asset1,
  asset10,
  asset11,
  asset12,
  asset13,
  asset14,
  asset15,
  asset16,
  asset17,
  asset18,
  asset19,
  asset2,
  asset20,
  asset21,
  asset22,
  asset23,
  asset24,
  asset25,
  asset26,
  asset27,
  asset28,
  asset29,
  asset3,
  asset30,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  asset9,
} from "../assets/partners";

export default class ClientSlider extends Component {
  render() {
    const settings = {
      centerMode: true,
      infinite: true,
      autoplay: true,
      centerPadding: "0",
      slidesToShow: 6,
      cssEase: "linear",
      speed: 5000,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      draggable: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };
    return (
      <div
        className="my-[30px] mb-[60px] bg-white px-4 sm:px-8 md:px-12 w-full"
        data-aos="fade-in"
      >
        <h1 className="text-teal text-[24px] font-sans1 mb-8">Our partners</h1>
        <Slider {...settings}>
          <div className="flex justify-center items-center h-32">
            <img src={asset1} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset2} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset3} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset4} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset5} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset6} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset7} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset8} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset9} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset10} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset11} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset12} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset13} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset14} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset15} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset16} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset17} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset18} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset19} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset20} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset21} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset22} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset23} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset24} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset25} alt="partner" className="object-none h-32 w-full" />
          </div>
          <div className="flex justify-center items-center h-32">
            <img src={asset26} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset27} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset28} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset29} alt="partner" className="object-none h-32 w-full" />
          </div>{" "}
          <div className="flex justify-center items-center h-32">
            <img src={asset30} alt="partner" className="object-none h-32 w-full" />
          </div>
        </Slider>
      </div>
    );
  }
}
