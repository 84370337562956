import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  engeenired1,
  engeenired2,
  engeenired3,
  engeenired4,
  engeenired5,
  engeenired6,
  ListSvg,
  Logo,
  Logo2,
} from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

const arrayList = [
  "Wellhead Seal Isolations",
  "Gate Valve Isolations",
  "Control Line Plugging",
  "Control Line Leak Repair",
  "Packer and Casing Shoe Isolations",
  "Pipeline and Process Valve Isolations",
];

const Engeenired = () => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-blue1">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12 space-y-8">
            {/* 1 */}
            <div>
              {/* Section name */}
              <div className="flex items-center space-x-1">
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.25 },
                  }}
                  viewport={{ once: true }}
                >
                  <ListSvg
                    rotate=""
                    size="w-6 h-6"
                    opacity="opacity-50"
                    fill={`fill-teal stroke-teal`}
                  />
                </motion.div>
                <motion.p
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.25, delay: 0.2 },
                  }}
                  viewport={{ once: true }}
                  className="text-sm text-teal"
                >
                  Energy sector<span> </span>
                  <motion.span
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: { duration: 0.25, delay: 0.4 },
                    }}
                    viewport={{ once: true }}
                    className="text-white"
                  >
                    Fully Engineered Solutions
                  </motion.span>
                </motion.p>
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                {/* CardImg */}
                <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                  >
                    Engineered Leak Sealing
                  </motion.h1>
                  <CardImg
                    images={[
                      engeenired1,
                      engeenired3,
                      engeenired5,
                      engeenired6,
                    ]}
                  />
                </div>
                {/* Content */}
                <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                  <div>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.3 },
                      }}
                      viewport={{ once: true }}
                      className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                    >
                      Engineered Leak Sealing
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.4 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      We are provider for bespoke engineered leak sealing
                      solutions for mature or aging oil & gas facilities.
                    </motion.p>
                  </div>
                  <div className="space-y-6">
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.5 },
                      }}
                      viewport={{ once: true }}
                      className="text-2xl sm:text-3xl pr-6 md:pr-16"
                    >
                      The Benefits of Leak Sealing Solutions
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.6 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70"
                    >
                      Understanding the relentless pressure faced by operators
                      to maintain full production and the need for
                      sustainability, especially on ageing platforms, we havea
                      range of brownfield products which will reduce costs,
                      minimise downtime, maintain or increase production and
                      extend the life of mature assets: surface, subsea,
                      refineries, downhole, wellheads, topsides and pipelines.
                    </motion.p>
                  </div>
                  <div>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="text-2xl sm:text-3xl pr-6 md:pr-16"
                    >
                      The Benefits of Leak Sealing Solutions
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-4"
                    >
                      We have a wide range of solutions available and these are
                      suitable across a range of areas such as sub sea, surface,
                      refinery, downhole and topside pipeline.
                    </motion.p>
                    <div className="mt-6">
                      <motion.h1
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{
                          opacity: 1,
                          y: 0,
                          transition: { duration: 0.25, delay: 0.2 },
                        }}
                        viewport={{ once: true }}
                      >
                        ISOLATIONS
                      </motion.h1>
                      <div className="flex flex-col mt-4 space-y-1">
                        {arrayList.map((text, key) => (
                          <div className="flex space-x-2">
                            <motion.div
                              initial={{ opacity: 0, x: -20 }}
                              whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: { duration: 0.25, delay: 0.3 },
                              }}
                              viewport={{ once: true }}
                            >
                              <ListSvg
                                rotate=""
                                size="w-3 h-6"
                                opacity="opacity-50"
                                fill={`fill-teal stroke-teal`}
                              />
                            </motion.div>
                            <motion.p
                              initial={{ opacity: 0, x: -20 }}
                              whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: { duration: 0.25, delay: 0.45 },
                              }}
                              viewport={{ once: true }}
                              className="text-sm"
                              key={key}
                            >
                              {text}
                            </motion.p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div>
              <div className="flex flex-col md:flex-row mt-2">
                {/* CardImg */}
                <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                  >
                    Bonding and Fastening solutions
                  </motion.h1>
                  <CardImg images={[engeenired2, engeenired4]} />
                </div>
                {/* Content */}
                <div className="flex flex-col text-white space-y-10 mt-10 sm:w-2/3 md:w-1/2 md:pr-40">
                  <div>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                    >
                      Bonding and Fastening solutions
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      We provide durable composite bonding & fastening solutions
                      that facilitate structural works from the construction
                      phase through life extension of large industrial
                      structures, either made of steel or concrete, and with
                      neither business interruption nor risk of explosion.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      Our mission is to increase safety and productivity by
                      reducing associated cost and risks.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      The “no hot work” solutions for “Explosive Atmospheres”
                      allowing both no business interruption associated to
                      maintenance operations and a dramatic risk reduction of
                      explosion. The “non-intrusive” solutions eradicate
                      associated costs to damage of the substrate which is
                      particularly attractive for all civil engineering
                      structures made of concrete, composite, glass, or steel.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      Repeatability thanks to a process controlled installation
                      Enhance safety in harsh industrial conditions.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      C-HAWK is the perfect tool for safe structural
                      modifications in hazardous environments (explosive
                      atmospheres). It can be combined with cold cutting and
                      ATEX surface preparation tools (zone 1) to allow
                      structural works during live production.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-6"
                    >
                      From an operator's perspective, it also reduces
                      musculoskeletal disorders as it does not generate intense
                      vibrations and is self-supported.
                    </motion.p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
};

export default transition(Engeenired);
