import React from "react";
import { mWound, wound2, wound3, wound4, wound5 } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Wound() {
  return (
    <MediczCard
      images={[mWound, wound2, wound3, wound4, wound5]}
      title="Wound care products"
      text="We primarily aim at ensuring timely wound healing and effective Infection Control. Choosing the correct wound care product will reduce recovery time and promote healing and repair. We Focus on providing products which are guided by cost, effortless application and End-User preference. We have an array of adequately stocked wound care supplies such as Non-Woven Wound Dressing, PU Transparent Wound Dressing, Absorbent Dressings and Silver Dressing."
    />
  );
}

export default transition(Wound);
