import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BgSvg } from "../assets";

interface INavbar {
  navbarBg: string;
  navbarBorder: string;
  logo: any;
  hamburgerSpan: string;
  hoverSpanBg: string;
  mobileBg: string;
  textColor: string;
  hoverBg: string;
  borderColor: string;
  hoverTextColor: string;
}

const Navbar = (props: INavbar) => {
  const [isOpen, setIsopen] = useState(false);
  const [dropEnergy, setDropEnergy] = useState(false);
  const [dropCivil, setDropCivil] = useState(false);
  const [dropMedicz, setDropMedicz] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/", { state: { scrollToId: "innovation" } });
  };

  const toggleMenu = () => {
    setIsopen(!isOpen);
  };

  return (
    <>
      <div
        className={`${props.navbarBg} ${props.navbarBorder} flex justify-center z-100`}
      >
        <div className="flex justify-between w-full content px-4 sm:px-8 md:px-12 pt-4">
          {/* Logo */}
          <AnimatePresence mode="wait">
            <motion.div
              className="md:w-1/2 pointer z-40 md:z-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 2, delay: 0.25 }}
            >
              <Link to="/">{props.logo}</Link>
            </motion.div>
          </AnimatePresence>
          {/* Content */}
          <div
            className={`flex items-center justify-between md:w-1/2 md:border-b ${props.borderColor} md:pb-3`}
          >
            {/* Hamburger btn */}
            <div className={`md:hidden rounded-full w-fit p-3`}>
              <button
                onClick={toggleMenu}
                id="menu-btn"
                className={`block hamburger focus:outline-none z-40 ${
                  isOpen && "open"
                }`}
              >
                <span className={`hamburger-top ${props.hamburgerSpan}`}></span>
                <span
                  className={`hamburger-middle ${props.hamburgerSpan}`}
                ></span>
                <span
                  className={`hamburger-bottom ${props.hamburgerSpan}`}
                ></span>
              </button>
            </div>
            {/* Desktop menu */}
            <div
              className={`${props.textColor} w-full justify-between hidden md:flex md:items-center h-full`}
            >
              {/* Energy */}
              <Link className="flex items-center h-min" to="/energy">
              <motion.button
                onHoverStart={() => setDropEnergy(true)}
                onHoverEnd={() => setDropEnergy(false)}
                className="relative flex flex-col items-center justify-center group"
              >
                <motion.a
                  initial={{ x: -25 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 0.4, delay: 0.25 }}
                  href=""
                  className="text-sm"
                >
                  Energy
                </motion.a>
                <div
                  className={`w-0 ${props.hoverSpanBg} group-hover:border group-hover:w-6 duration-200 absolute -bottom-5`}
                ></div>
                {/* Hover Dropdown */}
                {dropEnergy && (
                  <AnimatePresence>
                    <motion.div
                      onHoverStart={() => setDropEnergy(true)}
                      onHoverEnd={() => setDropEnergy(false)}
                      className="absolute top-4 w-48 bg-transparent pt-6 self-start cursor-default z-50"
                    >
                      <div className="bg-greenGradient py-6 px-5 top-10">
                        <motion.ul
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2, ease: "linear" }}
                          className="hoverEffect flex flex-col text-sm text-white space-y-5 cursor-default"
                        >
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/inspection">
                                Inspection and Shutdown support
                              </Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.15 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/engeenired">
                                Fully Engineered Solutions
                              </Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.25 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/monitoring">Monitoring Services</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.35 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/procurement">Procurement Support</Link>
                            </p>
                          </motion.li>
                        </motion.ul>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </motion.button>
              </Link>
              {/* Civil */}
              <Link className="flex items-center h-min" to="/civil">

              <motion.button
                onHoverStart={() => setDropCivil(true)}
                onHoverEnd={() => setDropCivil(false)}
                className="relative flex flex-col items-center justify-center group h-full"
              >
                <motion.a
                  initial={{ x: -25 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 0.4, delay: 0.25 }}
                  href=""
                  className="text-sm"
                >
                  Civil
                </motion.a>
                <div
                  className={`w-0 ${props.hoverSpanBg} group-hover:border group-hover:w-6 duration-200 absolute -bottom-5`}
                ></div>
                {/* Hover Dropdown */}
                {dropCivil && (
                  <AnimatePresence>
                    <motion.div
                      onHoverStart={() => setDropCivil(true)}
                      onHoverEnd={() => setDropCivil(false)}
                      className="absolute top-4 w-48 bg-transparent pt-6 self-start cursor-default z-50"
                    >
                      <div className="bg-greenGradient py-6 px-5 top-10">
                        <motion.ul
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2, ease: "linear" }}
                          className="hoverEffect flex flex-col text-white text-sm space-y-5 cursor-default"
                        >
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/design">Design & Architecture</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.15 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/access">Access Systems</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.25 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/facility">Facility Management</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.35 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/greenz">Greenz & Luxuriouz</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.45 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/product">Product Solutions</Link>
                            </p>
                          </motion.li>
                        </motion.ul>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </motion.button>
              </Link>
              {/* Medicz */}
              <Link className="flex items-center h-min" to="/medicz">

              <motion.button
                onHoverStart={() => setDropMedicz(true)}
                onHoverEnd={() => setDropMedicz(false)}
                className="relative flex flex-col items-center justify-center group"
              >
                <motion.a
                  initial={{ x: -25 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 0.4, delay: 0.25 }}
                  href=""
                  className="text-sm"
                >
                  Medicz
                </motion.a>
                <div
                  className={`w-0 ${props.hoverSpanBg} group-hover:border group-hover:w-6 duration-200 absolute -bottom-5`}
                ></div>
                {/* Hover Dropdown */}
                {dropMedicz && (
                  <AnimatePresence mode="wait">
                    <motion.div
                      onHoverStart={() => setDropMedicz(true)}
                      onHoverEnd={() => setDropMedicz(false)}
                      className="absolute top-4 w-56 bg-transparent pt-6 self-start cursor-default z-50"
                    >
                      <div className="bg-greenGradient py-6 px-5 top-10">
                        <motion.ul
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2, ease: "linear" }}
                          className="hoverEffect flex flex-col text-white text-sm space-y-5 cursor-default"
                        >
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/homecare">Home care products</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.15 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/diognostic">Diagnostic products</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.25 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/emergency">Emergency & First Aid</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.35 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/anestesia">
                                Anesthesia & Respiratory
                              </Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.45 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/orthopedic">Orthopedic products</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.55 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/wound">Wound care products</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.65 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/pressure">Pressure care products</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.75 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/hospital">Medical furniture</Link>
                            </p>
                          </motion.li>
                          <motion.li
                            initial={{ opacity: 0, y: -25 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.85 }}
                            className="text-left"
                          >
                            <p>
                              <Link to="/laboratory">
                                Laboratory consumables
                              </Link>
                            </p>
                          </motion.li>
                        </motion.ul>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </motion.button>
              </Link>
              <button className="relative flex flex-col items-center justify-center group">
                <motion.a
                  initial={{ x: -25 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 0.7, delay: 0.25 }}
                  href=""
                  className="text-sm"
                >
                  <Link to="/about">About us</Link>
                </motion.a>

                <div
                  className={`w-0 ${props.hoverSpanBg} group-hover:border group-hover:w-6 duration-200 absolute -bottom-5`}
                ></div>
              </button>
            </div>
            {/* Get in touch btn */}
            <button
              onClick={handleClick}
              className={`hidden text-sm ${props.textColor} w-44 lg:ml-20 xl:block border-l pl-6 ${props.borderColor} ml-10 ${props.hoverBg} ${props.hoverTextColor} duration-500 pr-5 py-2`}
            >
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
              className={`${props.mobileBg} flex flex-col min-h-screen absolute top-0 right-0 left-0 z-30`}
            >
              <div className="relative min-h-screen">
                <div className="flex flex-col items-end relative">
                  <div className="self-end absolute top-52 left-0">
                    <BgSvg />
                  </div>
                  <div
                    className={`${props.textColor} space-y-8 flex flex-col items-end font-sans1 mt-24 pt-20 px-4 sm:px-8`}
                  >
                    <motion.a
                      initial={{ y: 25 }}
                      animate={{ y: 0 }}
                      exit={{ y: 25 }}
                      transition={{ duration: 0.6 }}
                      className="text-4xl"
                      href="#energySector"
                    >
                      <Link to="/">Home</Link>
                    </motion.a>
                    <motion.a
                      initial={{ y: 25 }}
                      animate={{ y: 0 }}
                      exit={{ y: 25 }}
                      transition={{ duration: 0.6 }}
                      className="text-4xl"
                      href="#energySector"
                    >
                      <Link to="/energy">Energy</Link>
                    </motion.a>
                    <motion.a
                      initial={{ y: 50 }}
                      animate={{ y: 0 }}
                      exit={{ y: 50 }}
                      transition={{ duration: 0.7 }}
                      className="text-4xl"
                      href="#civilSector"
                    >
                      <Link to="/civil">Civil</Link>
                    </motion.a>
                    <motion.a
                      initial={{ y: 75 }}
                      animate={{ y: 0 }}
                      exit={{ y: 75 }}
                      transition={{ duration: 0.8 }}
                      className="text-4xl"
                      href="#mediczSector"
                    >
                      <Link to="/medicz">Medicz</Link>
                    </motion.a>
                    <motion.a
                      initial={{ y: 100 }}
                      animate={{ y: 0 }}
                      exit={{ y: 100 }}
                      transition={{ duration: 0.9 }}
                      className="text-4xl"
                      href=""
                    >
                      <Link to="/about">About us</Link>
                    </motion.a>
                  </div>
                </div>
                <div className="rectangleTeal self-end absolute -bottom-44 -right-12"></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default Navbar;
