import React from "react";


const ListSvg = (props: any) => {
  return (
    <svg
      className={`${props.rotate} ${props.opacity} ${props.size} ${props.fill}`}
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="15"
      viewBox="0 0 7 15"
      fill="none"
    >
      <path
        d="M6.39474 4.7V4.40028L6.13039 4.25901L2.20884 2.16344L6.5 4.30902V10.691L2.20883 12.8366L6.13039 10.741L6.39474 10.5997V10.3V4.7Z"
      />
    </svg>
  );
};

export default ListSvg;
