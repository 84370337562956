import React from "react";

const Logo = () => {
  return (
    <svg
      width="137"
      height="38"
      viewBox="0 0 137 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_148_430)">
        <path
          d="M15.8393 0L10.7422 2.89944V15.4818H15.8393V0Z"
          fill="#BBBBBB"
        />
        <path
          d="M15.834 36.5436L20.9311 33.6442V21.7183H15.834V36.5436Z"
          fill="#E1F8FF"
        />
        <path
          d="M10.7971 27.572L5.04231 24.4538V6.23657L0 9.13603V27.4079L15.8943 36.5438V21.609H10.7971V27.572Z"
          fill="#BBBBBB"
        />
        <path
          d="M31.7283 27.4078V9.13596L15.834 0V15.5366H20.9311V9.02652L26.686 12.1448V30.3619L31.7283 27.4078Z"
          fill="#E1F8FF"
        />
        <path
          d="M46.9129 26.457V18.6887H40.9936V26.457H37.2119V8.67749H40.9936V15.6799H46.9129V8.67749H50.6946V26.457H46.9129Z"
          fill="#E1F8FF"
        />
        <path
          d="M63.9033 26.457L62.9166 22.4634H57.3263L56.3397 26.457H52.3936L57.8743 8.67749H62.4782L67.9588 26.457H63.9033ZM58.0388 19.6734H62.2041L60.1214 11.4675L58.0388 19.6734Z"
          fill="#E1F8FF"
        />
        <path
          d="M83.0311 26.457H78.1533L72.5084 12.3428C72.6726 13.5464 72.7823 14.6952 72.8919 15.7893C73.0016 16.8834 73.0561 18.1416 73.0561 19.564V26.457H69.6035V8.67749H74.3716L80.1262 22.8464C79.7978 20.5488 79.633 18.4152 79.633 16.5005V8.67749H83.0862L83.0311 26.457Z"
          fill="#E1F8FF"
        />
        <path
          d="M95.5821 8.8416C96.5134 9.22457 97.3908 9.77165 98.2124 10.5375L96.4037 12.6163C95.7463 12.124 95.1434 11.7411 94.4859 11.4675C93.8279 11.2487 93.1705 11.0846 92.4579 11.0846C91.6908 11.0846 91.0327 11.2487 90.5395 11.5769C90.0462 11.9052 89.8269 12.3975 89.8269 12.9993C89.8269 13.4369 89.9366 13.7652 90.1008 14.0387C90.3201 14.3122 90.6491 14.5858 91.1424 14.8046C91.6356 15.0234 92.4027 15.297 93.3898 15.6252C95.1434 16.1722 96.4589 16.8834 97.3357 17.704C98.2124 18.5246 98.6512 19.7282 98.6512 21.2599C98.6512 22.354 98.3773 23.3387 97.8289 24.1593C97.2811 24.9799 96.4589 25.6365 95.4173 26.1288C94.3763 26.6212 93.1153 26.84 91.7453 26.84C90.3201 26.84 89.0598 26.6211 87.9636 26.1835C86.8675 25.7458 85.9355 25.144 85.1133 24.3782L87.0317 22.2446C87.7443 22.8464 88.4569 23.2841 89.1694 23.5576C89.882 23.8311 90.7037 23.9952 91.5811 23.9952C92.5124 23.9952 93.225 23.7764 93.8279 23.3387C94.4308 22.9011 94.6501 22.2994 94.6501 21.5335C94.6501 21.0411 94.5405 20.6581 94.3763 20.3299C94.1569 20.0017 93.8279 19.7282 93.3347 19.4546C92.8414 19.1811 92.184 18.9075 91.252 18.634C89.3336 18.0323 87.9085 17.321 87.0868 16.5005C86.2646 15.6799 85.8259 14.5857 85.8259 13.2181C85.8259 12.2334 86.1004 11.3581 86.6481 10.5922C87.1965 9.82631 87.9085 9.22457 88.8404 8.8416C89.7724 8.40396 90.8685 8.23987 92.0743 8.23987C93.4988 8.23987 94.595 8.45868 95.5821 8.8416Z"
          fill="#E1F8FF"
        />
        <path
          d="M112.573 9.33395C113.778 10.0452 114.655 11.1393 115.313 12.507C115.971 13.8746 116.245 15.5705 116.245 17.5399C116.245 19.4546 115.916 21.1505 115.313 22.5182C114.71 23.8858 113.778 24.9799 112.573 25.7458C111.367 26.457 109.996 26.84 108.352 26.84C106.708 26.84 105.283 26.457 104.132 25.7458C102.926 25.0347 102.049 23.9405 101.391 22.5729C100.734 21.1505 100.46 19.5093 100.46 17.5399C100.46 15.6252 100.789 13.984 101.446 12.5616C102.104 11.1393 103.036 10.0998 104.187 9.33395C105.392 8.56812 106.763 8.23987 108.407 8.23987C109.996 8.23987 111.421 8.62278 112.573 9.33395ZM105.392 12.6163C104.735 13.6558 104.406 15.297 104.406 17.5399C104.406 19.7829 104.735 21.424 105.392 22.4635C106.05 23.5029 107.037 23.9952 108.352 23.9952C109.668 23.9952 110.654 23.5029 111.312 22.4635C111.97 21.424 112.298 19.7829 112.298 17.5399C112.298 15.297 111.97 13.601 111.312 12.6163C110.654 11.577 109.668 11.0846 108.352 11.0846C107.037 11.0846 106.05 11.6316 105.392 12.6163Z"
          fill="#E1F8FF"
        />
        <path
          d="M132.577 26.457H127.699L122.054 12.3428C122.218 13.5464 122.328 14.6952 122.438 15.7893C122.548 16.8834 122.602 18.1416 122.602 19.564V26.457H119.149V8.67749H123.918L129.672 22.8464C129.344 20.5488 129.179 18.4152 129.179 16.5005V8.67749H132.632V26.457H132.577Z"
          fill="#E1F8FF"
        />
        <path
          d="M70.9736 37.8907H70.6445V31.1619H70.9736V37.8907Z"
          fill="#E1F8FF"
        />
        <path
          d="M77.5504 37.8906H77.1124L73.6046 31.5447C73.6591 31.9276 73.6591 32.4747 73.6591 33.6235V37.8359H73.3301V31.1071H73.7688L77.2766 37.4529C77.2766 37.07 77.2214 36.4135 77.2214 35.4835V31.1071H77.5504V37.8906Z"
          fill="#E1F8FF"
        />
        <path
          d="M83.3056 31.5447H81.2775V37.9454H80.9485V31.5447H78.9204V31.2166H83.4147L83.3056 31.5447Z"
          fill="#E1F8FF"
        />
        <path
          d="M87.854 31.49H85.0043V34.2254H87.4705V34.5536H85.0043V37.6171H87.9637V37.9454H84.6753V31.2166H87.9092L87.854 31.49Z"
          fill="#E1F8FF"
        />
        <path
          d="M91.4165 34.7724H89.8823V37.8907H89.5532V31.1617H91.0881C92.6774 31.1617 93.39 31.8183 93.39 32.9123C93.39 33.9518 92.7871 34.4441 91.8552 34.663L93.7184 37.8359H93.2804L91.4165 34.7724ZM91.1426 34.4441C92.2939 34.4441 93.0058 34.0065 93.0058 32.9671C93.0058 32.0371 92.4581 31.49 91.0881 31.49H89.9368V34.4441H91.1426Z"
          fill="#E1F8FF"
        />
        <path
          d="M99.4738 37.8906H99.0351L95.5273 31.5447C95.5824 31.9276 95.5824 32.4747 95.5824 33.6235V37.8359H95.2534V31.1071H95.6921L99.1993 37.4529C99.1993 37.07 99.1447 36.4135 99.1447 35.4835V31.1071H99.4738V37.8906Z"
          fill="#E1F8FF"
        />
        <path
          d="M104.899 35.976H101.885L101.227 37.8907H100.898L103.2 31.1617H103.639L105.941 37.8907H105.557L104.899 35.976ZM104.789 35.6477L103.365 31.4353L101.94 35.6477H104.789Z"
          fill="#E1F8FF"
        />
        <path
          d="M110.216 31.5447H108.188V37.9454H107.859V31.5447H105.831V31.2166H110.325L110.216 31.5447Z"
          fill="#E1F8FF"
        />
        <path
          d="M111.915 37.8907H111.586V31.1617H111.915V37.8907Z"
          fill="#E1F8FF"
        />
        <path
          d="M119.15 34.5536C119.15 36.7418 118.108 38 116.464 38C114.874 38 113.779 36.7965 113.779 34.5536C113.779 32.3106 114.874 31.0524 116.464 31.0524C118.054 31.1071 119.15 32.3106 119.15 34.5536ZM114.162 34.5536C114.162 36.6324 115.094 37.6718 116.464 37.6718C117.889 37.6718 118.766 36.6324 118.766 34.5536C118.766 32.4201 117.834 31.3806 116.464 31.3806C115.094 31.4353 114.162 32.4747 114.162 34.5536Z"
          fill="#E1F8FF"
        />
        <path
          d="M125.288 37.8906H124.85L121.342 31.5447C121.396 31.9276 121.396 32.4747 121.396 33.6235V37.8359H121.068V31.1071H121.506L125.014 37.4529C125.014 37.07 124.959 36.4135 124.959 35.4835V31.1071H125.288V37.8906Z"
          fill="#E1F8FF"
        />
        <path
          d="M130.714 35.976H127.699L127.042 37.8907H126.713L129.015 31.1617H129.454L131.756 37.8907H131.371L130.714 35.976ZM130.604 35.6477L129.179 31.4353L127.755 35.6477H130.604Z"
          fill="#E1F8FF"
        />
        <path
          d="M133.455 37.5624H136.304L136.25 37.8907H133.125V31.1617H133.455V37.5624Z"
          fill="#E1F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_148_430">
          <rect width="136.304" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;