import React from "react";
import { diagnostic1, diagnostic2, diagnostic3, diagnostic4, diagnostic5, mDiagnostic } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Diognostic() {
  return (
    <MediczCard
      images={[mDiagnostic, diagnostic1, diagnostic2, diagnostic3, diagnostic4, diagnostic5]}
      title="Diagnostic products"
      text="Diagnostic medical equipment helps clinicians to measure and observe various aspects of a patients health. Once a diagnosis is made, the clinician can then prescribe an appropriate treatment plan. We have a huge range consolidating modern medical diagnostic equipmentincluding Thermometers, Blood Pressure Monitors, Pulse Oximeters, Stethoscopes, Blood Glucometers, Spirometer."
    />
  );
}

export default transition(Diognostic);
