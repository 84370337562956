import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Logo2 } from "../assets";
import {
  AboutSection,
  CivilSector,
  EnergySector,
  Footer,
  Hero,
  Innovation,
  MediczSector,
  Welcome,
} from "../components";
import { Navbar } from "../containers";
import transition from "../transition";

const Home = () => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 903;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    if (location.state && location.state.scrollToId) {
      const targetElement = document.getElementById(location.state.scrollToId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  return (
    <>
      <Hero />
      <AboutSection />
      <Welcome />
      <EnergySector />
      <CivilSector />
      <MediczSector />
      <Innovation />
      <Footer goTop="#welcome" />

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
};

export default transition(Home);
