import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  ListSvg,
  Logo,
  Logo2,
  procurement,
  procurement1,
  procurement2,
  procurement3,
  procurement4,
  procurement5,
} from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

function Procurement() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-blue1">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center h-screen">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-teal stroke-teal`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-teal"
              >
                Energy sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Procurement solutions
                </motion.h1>
                <CardImg
                  images={[
                    procurement,
                    procurement1,
                    procurement2,
                    procurement3,
                    procurement4,
                    procurement5,
                  ]}
                />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Procurement solutions
                  </motion.h1>
                  <div className="space-y-3">
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.4 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl"
                    >
                      A global player with a local presence
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.5 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70"
                    >
                      Our customers look up to HNASON as a local procurement
                      integrator of foreign manufactured parts, helping them
                      achieve their local content objectives. With local
                      warehouses and local staff, HANSON’S local team is focused
                      on offering Maintenance, Repair & Operational (MRO) spare
                      part procurement services to our customer. Provision to
                      transact with a geographically-located local company with
                      strong global presence in different parts of the world.
                    </motion.p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Procurement);
