import { motion } from "framer-motion";
import React from "react";
import { aWhyHanson, TitleIcon } from "../assets";

const AWhyHanson = () => {
  return (
    <div className="flex justify-center">
      <div className="content w-full flex flex-col md:flex-row md:items-center px-4 sm:px-8 md:px-12 mt-12">
        {/* Content */}
        <div className="sm:w-3/4 md:w-1/2 md:pr-44">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3, delay: 0.3 },
            }}
            viewport={{ once: true }}
            className="flex space-x-4 items-center"
          >
            <TitleIcon />
            <h1 className="text-2xl sm:text-3xl text-teal font-sans1">
              Why Hanson International?
            </h1>
          </motion.div>
          <div className="text-darkBrown1 space-y-6 mt-6 sm:mt-8">
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.4 },
              }}
              viewport={{ once: true }}
            >
              We have always tried to be at the forefront of innovation and
              competitiveness in the Infra, Energy and Medical sectors. From
              this privileged position, we have been able to understand the
              needs of the market and respond with specific solutions, to reduce
              the cost of energy and the carbon footprint. For each challenge we
              must be able to put the statement well, and it is true that we
              have found many of the answers.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.5 },
              }}
              viewport={{ once: true }}
              className="sm:pr-24 md:pr-16"
            >
              Our track record supports our work, and our comprehensive business
              model makes our offer a reliable and innovative bet. From
              operations to strategy, HANSON fosters flexibility, increases
              performance and accelerates innovation, becoming a key ally for
              our clients.
            </motion.p>
          </div>
        </div>
        {/* Img */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.3, delay: 0.3 },
          }}
          viewport={{ once: true }}
          className="mt-12 md:w-1/2"
        >
          <img className="m-0 w-full" src={aWhyHanson} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default AWhyHanson;
