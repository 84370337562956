import React from "react";

const Logo2 = () => {
  return (
    <svg
      width="140"
      height="38"
      viewBox="0 0 140 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_172_186)">
        <path
          d="M16.1918 0L10.9814 2.89944V15.4818H16.1918V0Z"
          fill="#18978B"
        />
        <path
          d="M11.0371 27.5717L5.15436 24.4535V6.23633L0 9.13579V27.4076L16.2475 36.5436V21.6088H11.0371V27.5717Z"
          fill="#18978B"
        />
        <path
          d="M16.1855 36.5436L21.396 33.6442V21.7183H16.1855V36.5436Z"
          fill="#00617D"
        />
        <path
          d="M32.433 27.4078V9.13596L16.1855 0V15.5366H21.396V9.02652L27.2787 12.1448V30.3619L32.433 27.4078Z"
          fill="#00617D"
        />
        <path
          d="M47.9556 26.4572V18.689H41.9048V26.4572H38.0391V8.67773H41.9048V15.6801H47.9556V8.67773H51.8214V26.4572H47.9556Z"
          fill="#00617D"
        />
        <path
          d="M65.3231 26.4567L64.3145 22.4632H58.5999L57.5915 26.4567H53.5576L59.1602 8.67725H63.8663L69.4688 26.4567H65.3231ZM59.3283 19.6732H63.5862L61.4572 11.4673L59.3283 19.6732Z"
          fill="#00617D"
        />
        <path
          d="M84.8763 26.4572H79.8902L74.1198 12.343C74.2877 13.5466 74.3998 14.6954 74.5119 15.7896C74.624 16.8836 74.6797 18.1419 74.6797 19.5643V26.4572H71.1504V8.67773H76.0245L81.9069 22.8467C81.5712 20.549 81.4027 18.4154 81.4027 16.5007V8.67773H84.9327L84.8763 26.4572Z"
          fill="#00617D"
        />
        <path
          d="M97.7063 8.84148C98.6583 9.22445 99.5553 9.77153 100.395 10.5374L98.5462 12.6162C97.8742 12.1239 97.2579 11.7409 96.5858 11.4674C95.9131 11.2486 95.2411 11.0845 94.5127 11.0845C93.7285 11.0845 93.0558 11.2486 92.5516 11.5768C92.0475 11.905 91.8232 12.3974 91.8232 12.9992C91.8232 13.4368 91.9353 13.7651 92.1032 14.0386C92.3274 14.3121 92.6638 14.5857 93.168 14.8045C93.6721 15.0233 94.4563 15.2969 95.4653 15.625C97.2579 16.1721 98.6026 16.8833 99.4989 17.7039C100.395 18.5245 100.844 19.728 100.844 21.2598C100.844 22.3539 100.564 23.3386 100.003 24.1592C99.4431 24.9798 98.6026 25.6363 97.5378 26.1287C96.4737 26.621 95.1847 26.8399 93.7843 26.8399C92.3274 26.8399 91.0391 26.621 89.9186 26.1833C88.7981 25.7457 87.8454 25.1439 87.0049 24.3781L88.9659 22.2445C89.6943 22.8462 90.4228 23.2839 91.1512 23.5575C91.8796 23.831 92.7195 23.9951 93.6164 23.9951C94.5684 23.9951 95.2968 23.7763 95.9131 23.3386C96.5294 22.901 96.7537 22.2992 96.7537 21.5333C96.7537 21.041 96.6416 20.658 96.4737 20.3298C96.2495 20.0016 95.9131 19.728 95.409 19.4545C94.9048 19.181 94.2327 18.9074 93.2801 18.6339C91.319 18.0322 89.8622 17.3209 89.0223 16.5004C88.1818 15.6798 87.7333 14.5856 87.7333 13.218C87.7333 12.2333 88.0139 11.358 88.5738 10.5921C89.1344 9.82618 89.8622 9.22445 90.8148 8.84148C91.7675 8.40384 92.888 8.23975 94.1206 8.23975C95.5768 8.23975 96.6973 8.45856 97.7063 8.84148Z"
          fill="#00617D"
        />
        <path
          d="M115.074 9.33383C116.307 10.0451 117.203 11.1391 117.875 12.5068C118.548 13.8744 118.828 15.5704 118.828 17.5398C118.828 19.4545 118.491 21.1504 117.875 22.5181C117.259 23.8857 116.307 24.9798 115.074 25.7457C113.842 26.4569 112.441 26.8399 110.76 26.8399C109.079 26.8399 107.623 26.4569 106.446 25.7457C105.213 25.0345 104.317 23.9404 103.644 22.5728C102.972 21.1504 102.692 19.5092 102.692 17.5398C102.692 15.625 103.029 13.9839 103.701 12.5615C104.373 11.1391 105.325 10.0997 106.502 9.33383C107.734 8.568 109.135 8.23975 110.816 8.23975C112.441 8.23975 113.897 8.62266 115.074 9.33383ZM107.734 12.6162C107.062 13.6556 106.726 15.2969 106.726 17.5398C106.726 19.7828 107.062 21.4239 107.734 22.4633C108.407 23.5028 109.415 23.9951 110.76 23.9951C112.105 23.9951 113.113 23.5028 113.785 22.4633C114.458 21.4239 114.794 19.7828 114.794 17.5398C114.794 15.2969 114.458 13.6009 113.785 12.6162C113.113 11.5768 112.105 11.0845 110.76 11.0845C109.415 11.0845 108.407 11.6315 107.734 12.6162Z"
          fill="#00617D"
        />
        <path
          d="M135.524 26.4572H130.538L124.767 12.343C124.935 13.5466 125.047 14.6954 125.159 15.7896C125.271 16.8836 125.327 18.1419 125.327 19.5643V26.4572H121.798V8.67773H126.672L132.554 22.8467C132.219 20.549 132.05 18.4154 132.05 16.5007V8.67773H135.58V26.4572H135.524Z"
          fill="#00617D"
        />
        <path
          d="M72.5502 37.891H72.2139V31.1621H72.5502V37.891Z"
          fill="#00617D"
        />
        <path
          d="M79.2731 37.8905H78.8253L75.2396 31.5446C75.2953 31.9275 75.2953 32.4746 75.2953 33.6234V37.8358H74.959V31.1069H75.4074L78.9932 37.4528C78.9932 37.0699 78.9368 36.4134 78.9368 35.4834V31.1069H79.2731V37.8905Z"
          fill="#00617D"
        />
        <path
          d="M85.1565 31.5445H83.0833V37.9452H82.747V31.5445H80.6738V31.2163H85.2679L85.1565 31.5445Z"
          fill="#00617D"
        />
        <path
          d="M89.806 31.4903H86.893V34.2256H89.4139V34.5538H86.893V37.6174H89.9181V37.9456H86.5566V31.2168H89.8624L89.806 31.4903Z"
          fill="#00617D"
        />
        <path
          d="M93.4476 34.7723H91.8793V37.8905H91.543V31.1616H93.1119C94.7366 31.1616 95.465 31.8181 95.465 32.9122C95.465 33.9516 94.8487 34.444 93.8961 34.6629L95.8007 37.8358H95.3529L93.4476 34.7723ZM93.1677 34.444C94.3445 34.444 95.0723 34.0064 95.0723 32.967C95.0723 32.0369 94.5124 31.4899 93.1119 31.4899H91.935V34.444H93.1677Z"
          fill="#00617D"
        />
        <path
          d="M101.684 37.8905H101.236L97.6501 31.5446C97.7065 31.9275 97.7065 32.4746 97.7065 33.6234V37.8358H97.3701V31.1069H97.8186L101.404 37.4528C101.404 37.0699 101.348 36.4134 101.348 35.4834V31.1069H101.684V37.8905Z"
          fill="#00617D"
        />
        <path
          d="M107.231 35.9758H104.15L103.477 37.8905H103.141L105.494 31.1616H105.942L108.295 37.8905H107.903L107.231 35.9758ZM107.118 35.6476L105.662 31.4352L104.205 35.6476H107.118Z"
          fill="#00617D"
        />
        <path
          d="M112.665 31.545H110.591V37.9456H110.256V31.545H108.183V31.2168H112.777L112.665 31.545Z"
          fill="#00617D"
        />
        <path
          d="M114.402 37.8905H114.065V31.1616H114.402V37.8905Z"
          fill="#00617D"
        />
        <path
          d="M121.797 34.5534C121.797 36.7417 120.732 37.9999 119.052 37.9999C117.426 37.9999 116.307 36.7964 116.307 34.5534C116.307 32.3105 117.426 31.0522 119.052 31.0522C120.677 31.107 121.797 32.3105 121.797 34.5534ZM116.699 34.5534C116.699 36.6323 117.651 37.6717 119.052 37.6717C120.508 37.6717 121.405 36.6323 121.405 34.5534C121.405 32.4199 120.452 31.3805 119.052 31.3805C117.651 31.4352 116.699 32.4746 116.699 34.5534Z"
          fill="#00617D"
        />
        <path
          d="M128.072 37.8905H127.624L124.039 31.5446C124.094 31.9275 124.094 32.4746 124.094 33.6234V37.8358H123.759V31.1069H124.207L127.792 37.4528C127.792 37.0699 127.737 36.4134 127.737 35.4834V31.1069H128.072V37.8905Z"
          fill="#00617D"
        />
        <path
          d="M133.618 35.9758H130.537L129.865 37.8905H129.528L131.881 31.1616H132.33L134.683 37.8905H134.29L133.618 35.9758ZM133.506 35.6476L132.049 31.4352L130.593 35.6476H133.506Z"
          fill="#00617D"
        />
        <path
          d="M136.42 37.5623H139.333L139.278 37.8905H136.084V31.1616H136.42V37.5623Z"
          fill="#00617D"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_186">
          <rect width="139.333" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo2;