import React from "react";
import { homecare1, homecare2, homecare3, homecare4, homecare5, homecare6, mHomecare } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Homecare() {
  return (
    <MediczCard
      images={[mHomecare, homecare1, homecare2, homecare3, homecare4, homecare5, homecare6]}
      title="Homecare equipment"
      text="Homecare Equipment has a monumental role in helping people perform daily tasks on their own. We offer a vast range of products in homecare equipments including Nebulizers, Oxygen Concentrators, Patient Beds, Medical Mattresses, Wheelchairs, Commodes Chairs, Electrical Wheelchairs, Walkers Patient Hoist and Overbed Tables."
    />
  );
}

export default transition(Homecare);
