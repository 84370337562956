import React from "react";
import { emergency1, emergency2, emergency3, emergency4, emergency5, emergency6, emergency7, emergency8, mEmergency } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Emergency() {
  return (
    <MediczCard
      images={[mEmergency, emergency1, emergency2, emergency3, emergency4, emergency5, emergency6, emergency7, emergency8]}
      title="Emergency & First Aid"
      text="We provide all the Essentials for Basic and Emergent Medical Care. We have a complete range product required to minimize injury and Future disabilities. Our product range includes AED Machines, Ambulance Stretchers, Spine Boards, Head Immobilizers, Folding Stretchers, Suction Machines, First Aid Kits, Extrication Collars, Splints, Pelvic Slings, Chest Seal, Bleeding control kits, Burn Dressing, Instant Cold Pack and Eye Wash Station"
    />
  );
}

export default transition(Emergency);
