import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { IpadProperty, MobileProperty, TitleIcon } from "../assets";

// Animation variants
const textAnimation = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.3, ease: "linear" },
  }),
};

const propertyAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

const AboutSection = () => {
  return (
    <div className="flex justify-center">
      <motion.div className="w-full mt-12 md:mt-24 flex flex-col md:flex-row content">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3, once: true }}
          transition={{ delay: 0.25 }}
          className="order-2 md:order-1 flex justify-center w-full md:w-1/2 md:ml-0 overflow-hidden"
        >
          <motion.div variants={propertyAnimation} className="sm:hidden">
            <MobileProperty />
          </motion.div>
          <motion.div variants={propertyAnimation} className="hidden sm:block">
            <IpadProperty />
          </motion.div>
        </motion.div>
        <div className="md:order-2 flex md:bg-about h-[500px] lg:h-[700px] bg-no-repeat md:w-1/2 bg-right-bottom px-4 sm:px-8 md:px-12">
          <div className="md:w-4/5 sm:w-2/3 flex flex-col">
            {/* Title */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              custom={1}
              variants={textAnimation}
              className="flex items-center space-x-6"
            >
              <TitleIcon />
              <h1 className="text-teal text-2xl sm:text-3xl font-sans1">
                Why Hanson International?
              </h1>
            </motion.div>
            {/* Info */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              custom={1.5}
              variants={textAnimation}
              className="md:mt-12 mt-6 sm:mt-8"
            >
              <h1 className="text-2xl sm:text-3xl font-semibold leading-normal text-darkBrown1">
                We provide cutting edge <br /> eco-efficient{" "}
                <span className="text-blue1">technology</span> and{" "}
                <span className="text-blue1">services</span> to Industrial
                sectors.
              </h1>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              custom={2}
              variants={textAnimation}
              className="sm:mt-10 mt-6 md:w-3/4"
            >
              <p className="text-darkBrown2">
                HANSON International carries out its activities from a
                professional and independent standpoint, with a strong
                commitment to innovation, sustainable development and respect
                for the environment.
              </p>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              custom={2.5}
              variants={textAnimation}
              className="flex items-x-12 items-center mt-6 sm:mt-12"
            >
              <Link to="/about">
                <button className="flex justify-between items-center bg-teal px-6 py-3 rounded-full group">
                  <p className="font-sans1 text-white">About us</p>
                  <svg
                    className="ml-4 opacity-40 group-hover:ml-8 group-hover:opacity-100 duration-700"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="20"
                    viewBox="0 0 10 20"
                    fill="none"
                  >
                    <path
                      d="M8.5 6V5.70758L8.24513 5.56421L0.5 1.20758V0.828182L9 5.30187V14.6981L0.5 19.1718V18.7924L8.24513 14.4358L8.5 14.2924V14V6Z"
                      stroke="#FCFCFC"
                    />
                  </svg>
                </button>
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutSection;
