import { motion } from "framer-motion";
import React from "react";
import { civil, civilMD, civilSM, Logo } from "../assets";
import { MItem, Navbar } from "../containers";

// Animation variants
const Left = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "linear" },
  },
};

const Right = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "linear" },
  },
};

const PCivilSector = () => {
  return (
    <div className="bg-lightTeal1 flex justify-center">
      <div id="civil" className="content w-full">
        {/* Nav */}
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        {/* Hero */}
        <div className="text-white mt-12 md:mt-24 px-4 sm:px-8 md:px-12 flex flex-col items-center">
          {/* Content */}
          <div className="flex flex-col sm:flex-row">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.3 },
              }}
              viewport={{ once: true }}
              className="text-4xl sm:text-6xl font-sans1 w-1/2 sm:w-2/5 md:w-1/2 md:pr-80"
            >
              CIVIL SECTOR
            </motion.h1>
            <div className="text-[#fcfcfcb3] space-y-4 mt-6 sm:mt-0 sm:w-3/5 md:w-1/2">
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.3, delay: 0.5 },
                }}
                viewport={{ once: true }}
                className="md:pr-32"
              >
                We have a motto for the way we do business at HANSON: Innovation
                connects people. Our goal is to bring together our high
                standards regarding quality, technology and efficiency with
                every person involved in a project – whether a builder or a
                user. After all, we build with and for people. We believe that
                responsibility toward the environment and society is a key
                factor driving the long-term success of companies.
              </motion.p>
            </div>
          </div>
          {/* Img */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3, delay: 0.7 },
            }}
            viewport={{ once: true }}
            className="w-full mt-8 sm:mt-12 md:mt-24"
          >
            <img className="w-full sm:hidden" src={civil} alt="" />
            <img
              className="w-full hidden sm:block md:hidden"
              src={civilSM}
              alt=""
            />
            <img className="w-full hidden md:block" src={civilMD} alt="" />
          </motion.div>
        </div>
        {/* Content */}
        <div className="flex flex-col md:flex-row gap-2.5 flex-wrap px-4 sm:px-8 md:px-12 py-12 md:py-20">
          <div className="flex flex-col md:flex-row flex-wrap gap-2.5 mt-10">
            {/* 1 */}
            <MItem
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.75, once: true }}
              variants={Left}
              queue="01."
              bgColor="bg-teal"
              textColor="text-white"
              svg={
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 295 295"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M215.774 147.382C215.774 160.908 211.763 174.131 204.248 185.378C196.733 196.625 186.052 205.391 173.555 210.567C161.058 215.743 147.307 217.098 134.04 214.459C120.774 211.82 108.588 205.306 99.0229 195.742C89.4582 186.177 82.9446 173.991 80.3057 160.724C77.6668 147.458 79.0212 133.707 84.1976 121.21C89.3739 108.713 98.1398 98.0316 109.387 90.5167C120.633 83.0018 133.856 78.9908 147.383 78.9908L147.383 147.382L215.774 147.382Z"
                    fill="#FCFCFC"
                  />
                  <mask id="path-2-inside-1_417_301" fill="white">
                    <path d="M43.1669 147.382C43.1669 167.994 49.279 188.143 60.7303 205.281C72.1815 222.419 88.4577 235.777 107.501 243.665C126.543 251.552 147.497 253.616 167.713 249.595C187.929 245.574 206.498 235.648 221.073 221.074C235.648 206.499 245.573 187.929 249.594 167.714C253.616 147.498 251.552 126.544 243.664 107.501C235.776 88.4583 222.419 72.1821 205.281 60.7308C188.143 49.2795 167.994 43.1674 147.382 43.1674L147.382 120.272C152.744 120.272 157.985 121.862 162.444 124.841C166.902 127.82 170.377 132.054 172.429 137.008C174.481 141.961 175.018 147.412 173.972 152.671C172.926 157.93 170.344 162.761 166.552 166.553C162.761 170.344 157.93 172.926 152.671 173.972C147.412 175.018 141.961 174.481 137.007 172.43C132.053 170.378 127.819 166.903 124.84 162.444C121.861 157.986 120.271 152.744 120.271 147.382L43.1669 147.382Z" />
                  </mask>
                  <path
                    d="M43.1669 147.382C43.1669 167.994 49.279 188.143 60.7303 205.281C72.1815 222.419 88.4577 235.777 107.501 243.665C126.543 251.552 147.497 253.616 167.713 249.595C187.929 245.574 206.498 235.648 221.073 221.074C235.648 206.499 245.573 187.929 249.594 167.714C253.616 147.498 251.552 126.544 243.664 107.501C235.776 88.4583 222.419 72.1821 205.281 60.7308C188.143 49.2795 167.994 43.1674 147.382 43.1674L147.382 120.272C152.744 120.272 157.985 121.862 162.444 124.841C166.902 127.82 170.377 132.054 172.429 137.008C174.481 141.961 175.018 147.412 173.972 152.671C172.926 157.93 170.344 162.761 166.552 166.553C162.761 170.344 157.93 172.926 152.671 173.972C147.412 175.018 141.961 174.481 137.007 172.43C132.053 170.378 127.819 166.903 124.84 162.444C121.861 157.986 120.271 152.744 120.271 147.382L43.1669 147.382Z"
                    stroke="#00617D"
                    stroke-width="2"
                    mask="url(#path-2-inside-1_417_301)"
                  />
                </svg>
              }
              title="Design & Architecture"
              text="Looking for a way to improve your design and manage your construction effectively? Our team has vast experience with both traditional and digitalized projects."
              stroke="stroke-white"
              svgMargin="-ml-6 -mt-6 sm:-mt-12 sm:mr-6 sm:p-6"
              borderColor="border-blue1"
              mdWidth="md:w-49%"
              animate="animated-svg"
              linkTo="/design"
            />
            {/* 2 */}
            <MItem
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.75, once: true }}
              variants={Right}
              queue="02."
              bgColor="bg-teal"
              textColor="text-white"
              svg={
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 176 176"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M88 176C70.5952 176 53.5813 170.839 39.1098 161.169C24.6383 151.5 13.3591 137.756 6.6986 121.676C0.0380966 105.596 -1.70459 87.9024 1.69091 70.832C5.08641 53.7617 13.4676 38.0816 25.7746 25.7746C38.0816 13.4676 53.7617 5.08639 70.8321 1.69089C87.9024 -1.70461 105.596 0.0380865 121.676 6.6986C137.756 13.3591 151.5 24.6383 161.169 39.1098C170.839 53.5814 176 70.5953 176 88L129.858 88C129.858 79.7212 127.403 71.6283 122.804 64.7448C118.204 57.8612 111.667 52.4961 104.018 49.328C96.3699 46.1598 87.9536 45.3309 79.8339 46.946C71.7141 48.5611 64.2557 52.5477 58.4017 58.4017C52.5477 64.2557 48.5611 71.7141 46.946 79.8338C45.3309 87.9536 46.1598 96.3699 49.328 104.018C52.4961 111.667 57.8612 118.204 64.7448 122.804C71.6283 127.403 79.7212 129.858 88 129.858L88 176Z"
                    fill="#FCFCFC"
                  />
                  <mask id="path-2-inside-1_404_422" fill="white">
                    <path d="M14 88C14 102.438 18.34 116.552 26.4712 128.557C34.6025 140.561 46.1597 149.918 59.6814 155.443C73.2032 160.968 88.0821 162.414 102.437 159.597C116.791 156.781 129.977 149.828 140.326 139.619C150.675 129.41 157.723 116.402 160.578 102.242C163.433 88.081 161.968 73.4031 156.367 60.0641C150.766 46.7251 141.281 35.3241 129.112 27.3027C116.943 19.2814 102.636 15 88 15L88 70.5373C91.5011 70.5373 94.9236 71.5615 97.8347 73.4803C100.746 75.3991 103.015 78.1264 104.354 81.3173C105.694 84.5082 106.045 88.0194 105.362 91.4068C104.679 94.7942 102.993 97.9058 100.517 100.348C98.0415 102.79 94.8873 104.453 91.4535 105.127C88.0196 105.801 84.4604 105.455 81.2258 104.133C77.9912 102.812 75.2265 100.573 73.2814 97.7018C71.3363 94.83 70.2981 91.4538 70.2981 88L14 88Z" />
                  </mask>
                  <path
                    d="M14 88C14 102.438 18.34 116.552 26.4712 128.557C34.6025 140.561 46.1597 149.918 59.6814 155.443C73.2032 160.968 88.0821 162.414 102.437 159.597C116.791 156.781 129.977 149.828 140.326 139.619C150.675 129.41 157.723 116.402 160.578 102.242C163.433 88.081 161.968 73.4031 156.367 60.0641C150.766 46.7251 141.281 35.3241 129.112 27.3027C116.943 19.2814 102.636 15 88 15L88 70.5373C91.5011 70.5373 94.9236 71.5615 97.8347 73.4803C100.746 75.3991 103.015 78.1264 104.354 81.3173C105.694 84.5082 106.045 88.0194 105.362 91.4068C104.679 94.7942 102.993 97.9058 100.517 100.348C98.0415 102.79 94.8873 104.453 91.4535 105.127C88.0196 105.801 84.4604 105.455 81.2258 104.133C77.9912 102.812 75.2265 100.573 73.2814 97.7018C71.3363 94.83 70.2981 91.4538 70.2981 88L14 88Z"
                    stroke="#00617D"
                    stroke-width="2"
                    mask="url(#path-2-inside-1_404_422)"
                  />
                </svg>
              }
              title="Access Systems"
              text="HANSON International provides access solution for low and high sloped roofs, building, complicated structures, pipelines and vehicles."
              stroke="stroke-white"
              svgMargin="p-6 -ml-6 sm:ml-0 sm:p-16"
              borderColor="border-blue1"
              mdWidth="md:w-49%"
              animate="animated-svg"
              linkTo="/access"
            />
            {/* 3 */}
            <MItem
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.75, once: true }}
              variants={Left}
              queue="03."
              bgColor="bg-teal"
              textColor="text-white"
              svg={
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 295 295"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M215.774 147.382C215.774 160.908 211.763 174.131 204.248 185.378C196.733 196.625 186.052 205.391 173.555 210.567C161.058 215.743 147.307 217.098 134.04 214.459C120.774 211.82 108.588 205.306 99.0229 195.742C89.4582 186.177 82.9446 173.991 80.3057 160.724C77.6668 147.458 79.0212 133.707 84.1976 121.21C89.3739 108.713 98.1398 98.0316 109.387 90.5167C120.633 83.0018 133.856 78.9908 147.383 78.9908L147.383 147.382L215.774 147.382Z"
                    fill="#FCFCFC"
                  />
                  <mask id="path-2-inside-1_404_444" fill="white">
                    <path d="M147.382 43.1669C161.068 43.1669 174.62 45.8625 187.264 51.0997C199.908 56.337 211.396 64.0135 221.073 73.6907C230.751 83.368 238.427 94.8565 243.664 107.5C248.902 120.144 251.597 133.696 251.597 147.382H181.818C181.818 142.86 180.927 138.382 179.197 134.204C177.466 130.026 174.93 126.23 171.732 123.032C168.534 119.834 164.738 117.298 160.56 115.567C156.382 113.837 151.904 112.946 147.382 112.946L147.382 43.1669Z" />
                  </mask>
                  <path
                    d="M147.382 43.1669C161.068 43.1669 174.62 45.8625 187.264 51.0997C199.908 56.337 211.396 64.0135 221.073 73.6907C230.751 83.368 238.427 94.8565 243.664 107.5C248.902 120.144 251.597 133.696 251.597 147.382H181.818C181.818 142.86 180.927 138.382 179.197 134.204C177.466 130.026 174.93 126.23 171.732 123.032C168.534 119.834 164.738 117.298 160.56 115.567C156.382 113.837 151.904 112.946 147.382 112.946L147.382 43.1669Z"
                    stroke="#00617D"
                    stroke-width="2"
                    mask="url(#path-2-inside-1_404_444)"
                  />
                </svg>
              }
              title="Facility Management"
              text="Restore your commercial and industrial properties with HANSON’s expertise, from listed buildings to warehouse units."
              stroke="stroke-white"
              svgMargin="-ml-6 -mt-6 sm:-mt-12 sm:mr-6 sm:p-6"
              borderColor="border-blue1"
              mdWidth="md:w-49%"
              animate="animated-svg"
              linkTo="/facility"
            />
            {/* 4 */}
            <MItem
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.75, once: true }}
              variants={Right}
              queue="04."
              bgColor="bg-teal"
              textColor="text-white"
              svg={
                <svg
                  width="auto"
                  height="auto"
                  viewBox="0 0 209 209"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M158 170.833L50 170.833L50 63.314L104 33.833L158 63.314L158 170.833Z"
                    fill="#FCFCFC"
                  />
                  <mask id="path-2-inside-1_404_467" fill="white">
                    <path d="M-0.000265547 104.215C-0.000264351 90.529 2.69534 76.9773 7.93263 64.3333C13.1699 51.6894 20.8463 40.2008 30.5236 30.5236C40.2009 20.8463 51.6894 13.1699 64.3334 7.9326C76.9773 2.69532 90.529 -0.000284965 104.215 -0.000283769C117.9 -0.000282573 131.452 2.69532 144.096 7.93261C156.74 13.1699 168.229 20.8463 177.906 30.5236C187.583 40.2008 195.26 51.6894 200.497 64.3334C205.734 76.9773 208.43 90.529 208.43 104.215L138.651 104.215C138.651 99.6925 137.76 95.2146 136.029 91.0366C134.299 86.8587 131.762 83.0625 128.565 79.8648C125.367 76.6672 121.571 74.1306 117.393 72.4001C113.215 70.6695 108.737 69.7788 104.215 69.7788C99.6925 69.7788 95.2146 70.6695 91.0367 72.4001C86.8587 74.1306 83.0625 76.6671 79.8648 79.8648C76.6672 83.0625 74.1306 86.8587 72.4001 91.0366C70.6695 95.2146 69.7788 99.6925 69.7788 104.215L-0.000265547 104.215Z" />
                  </mask>
                  <path
                    d="M-0.000265547 104.215C-0.000264351 90.529 2.69534 76.9773 7.93263 64.3333C13.1699 51.6894 20.8463 40.2008 30.5236 30.5236C40.2009 20.8463 51.6894 13.1699 64.3334 7.9326C76.9773 2.69532 90.529 -0.000284965 104.215 -0.000283769C117.9 -0.000282573 131.452 2.69532 144.096 7.93261C156.74 13.1699 168.229 20.8463 177.906 30.5236C187.583 40.2008 195.26 51.6894 200.497 64.3334C205.734 76.9773 208.43 90.529 208.43 104.215L138.651 104.215C138.651 99.6925 137.76 95.2146 136.029 91.0366C134.299 86.8587 131.762 83.0625 128.565 79.8648C125.367 76.6672 121.571 74.1306 117.393 72.4001C113.215 70.6695 108.737 69.7788 104.215 69.7788C99.6925 69.7788 95.2146 70.6695 91.0367 72.4001C86.8587 74.1306 83.0625 76.6671 79.8648 79.8648C76.6672 83.0625 74.1306 86.8587 72.4001 91.0366C70.6695 95.2146 69.7788 99.6925 69.7788 104.215L-0.000265547 104.215Z"
                    stroke="#00617D"
                    stroke-width="2"
                    mask="url(#path-2-inside-1_404_467)"
                  />
                </svg>
              }
              title="Greenz & Luxuriouz"
              text="Sustainable interior home designs are growing in demand. People who want to decrease their environmental footprint want a trendy solution."
              stroke="stroke-white"
              svgMargin="p-6 -ml-6 -mt-6 sm:p-12 sm:ml-0"
              borderColor="border-blue1"
              mdWidth="md:w-49%"
              animate="animated-svg"
              linkTo="/greenz"
            />
            {/* 5 */}
            <div className="flex md: justify-end w-full md:-ml-4">
              <MItem
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.75, once: true }}
                variants={Right}
                queue="05."
                bgColor="bg-teal"
                textColor="text-white"
                svg={
                  <svg
                    width="auto"
                    height="auto"
                    viewBox="0 0 286 286"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M85 197L85 94L188.595 94L217 145.5L188.595 197L85 197Z"
                      fill="#FCFCFC"
                    />
                    <mask id="path-2-inside-1_404_455" fill="white">
                      <path d="M143.215 39.0039C163.827 39.0039 183.975 45.116 201.114 56.5673C218.252 68.0186 231.609 84.2948 239.497 103.338C247.385 122.38 249.448 143.334 245.427 163.55C241.406 183.766 231.481 202.335 216.906 216.91C202.331 231.485 183.762 241.41 163.546 245.431C143.33 249.453 122.376 247.389 103.333 239.501C84.2907 231.613 68.0145 218.256 56.5632 201.118C45.1119 183.98 38.9998 163.831 38.9998 143.219L108.779 143.219C108.779 150.03 110.799 156.688 114.582 162.35C118.366 168.013 123.744 172.427 130.037 175.034C136.329 177.64 143.253 178.322 149.933 176.993C156.613 175.664 162.749 172.385 167.565 167.569C172.381 162.753 175.66 156.617 176.989 149.937C178.318 143.257 177.636 136.333 175.029 130.041C172.423 123.749 168.009 118.37 162.346 114.587C156.683 110.803 150.026 108.783 143.215 108.783L143.215 39.0039Z" />
                    </mask>
                    <path
                      d="M143.215 39.0039C163.827 39.0039 183.975 45.116 201.114 56.5673C218.252 68.0186 231.609 84.2948 239.497 103.338C247.385 122.38 249.448 143.334 245.427 163.55C241.406 183.766 231.481 202.335 216.906 216.91C202.331 231.485 183.762 241.41 163.546 245.431C143.33 249.453 122.376 247.389 103.333 239.501C84.2907 231.613 68.0145 218.256 56.5632 201.118C45.1119 183.98 38.9998 163.831 38.9998 143.219L108.779 143.219C108.779 150.03 110.799 156.688 114.582 162.35C118.366 168.013 123.744 172.427 130.037 175.034C136.329 177.64 143.253 178.322 149.933 176.993C156.613 175.664 162.749 172.385 167.565 167.569C172.381 162.753 175.66 156.617 176.989 149.937C178.318 143.257 177.636 136.333 175.029 130.041C172.423 123.749 168.009 118.37 162.346 114.587C156.683 110.803 150.026 108.783 143.215 108.783L143.215 39.0039Z"
                      stroke="#00617D"
                      stroke-width="2"
                      mask="url(#path-2-inside-1_404_455)"
                    />
                  </svg>
                }
                title="Product Solutions"
                text="Our agile team-based organization is made up of the brightest product designers, engineers and manufacturing specialists around the world."
                stroke="stroke-white"
                svgMargin="-ml-6 -mt-6 sm:-mt-12 sm:mr-6 sm:p-6"
                borderColor="border-blue1"
                mdWidth="md:w-49%"
                animate="animated-svg"
                linkTo="/product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCivilSector;
