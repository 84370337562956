import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Navbar } from "../containers";
import { Logo } from "../assets";
import { Link } from "react-router-dom";

const Hero = () => {
  const heroSection = [
    {
      class: "bg-hero1",
      title: "Sustainable development for the environment",
      text: "HANSON International carries out its activities from a professional and independent standpoint, with a strong commitment to innovation, sustainable development and respect for the environment.",
      goTo: "energy",
    },
    {
      class: "bg-hero2",
      title: "Quality medical supplies that you need",
      text: "Medicz provides the technology and solutions that support the logistics, contracts and processes behind the quality medical supplies and healthcare equipment that you need.",
      goTo: "medicz",
    },
    {
      class: "bg-hero3",
      title: "We build with and for people",
      text: "We have a motto for the way we do business at HANSON: Innovation connects people. Our goal is to bring together our high standards regarding quality, technology and efficiency with every person involved in a project – whether a builder or a user.",
      goTo: "civil",
    },
  ];

  const [index, setIndex] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleClickNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % heroSection.length);
    setDisabledBtn(true);
    setTimeout(() => {
      setDisabledBtn(false);
    }, 1500);
  };

  const handleClickPrev = () => {
    setIndex(
      (prevIndex) => (prevIndex - 1 + heroSection.length) % heroSection.length
    );
    setDisabledBtn(true);
    setTimeout(() => {
      setDisabledBtn(false);
    }, 1500);
  };

  const currentContent = heroSection[index];

  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={`${currentContent.class} h-screen w-full bg-no-repeat bg-cover bg-[center_left_-18rem] md:bg-center flex justify-center transition-all duration-1000`}
      >
        <div className="content flex flex-col justify-between">
          <Navbar
            navbarBg="bg-transparent"
            navbarBorder=""
            logo={<Logo />}
            hamburgerSpan="bg-white"
            hoverSpanBg="bg-white"
            mobileBg="bg-darkTeal"
            textColor="text-white"
            hoverBg="hover:bg-white"
            borderColor="border-borderB"
            hoverTextColor="hover:text-black"
          />
          {/* Hero */}
          <div className="text-white flex flex-col md:flex-row px-4 sm:px-8 md:px-12 pb-20">
            {/* Title */}
            <div className="md:w-1/2 pr-12 sm:pr-32 md:h-60">
              <AnimatePresence mode="wait">
                <motion.h1
                  key={currentContent.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {ease: "linear" },
                  }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="text-[40px] sm:text-6xl font-sans1 leading-snug sm:leading-tight"
                >
                  {currentContent.title}
                </motion.h1>
              </AnimatePresence>
            </div>
            {/* Text */}
            <div className="md:w-1/2 pr-6 sm:pr-0 mt-6 md:mt-0">
              <div className="h-full flex flex-col justify-between">
                <AnimatePresence mode="wait">
                  <motion.p
                    key={currentContent.text}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { delay: 0.3, ease: "linear" },
                    }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    className="font-base sm:w-2/3"
                  >
                    {currentContent.text}
                  </motion.p>
                </AnimatePresence>
                {/* Buttons */}
                <div className="flex md:space-x-12 mt-12 md:mt-0 items-center justify-between md:justify-normal">
                  <Link to={`/${currentContent.goTo}`}>
                    <button className="flex justify-between items-center bg-btnBg hover:bg-btnHoverBg duration-700 px-6 py-3 rounded-full group">
                      <p className="font-sans1">Discover Sector</p>
                      <svg
                        className="ml-4 group-hover:ml-6 sm:group-hover:ml-10 duration-700 opacity-40 group-hover:opacity-100"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="20"
                        viewBox="0 0 10 20"
                        fill="none"
                      >
                        <path
                          d="M8.5 6V5.70758L8.24513 5.56421L0.5 1.20758V0.828182L9 5.30187V14.6981L0.5 19.1718V18.7924L8.24513 14.4358L8.5 14.2924V14V6Z"
                          stroke="#FCFCFC"
                        />
                      </svg>
                    </button>
                  </Link>
                  <div className="space-x-4">
                    <button
                      {...(disabledBtn && { disabled: disabledBtn })}
                      onClick={handleClickPrev}
                      className="bg-btnBg group hover:bg-btnHoverBg duration-700 px-4 py-3 rounded-full cursor-pointer"
                    >
                      <svg
                        className="opacity-40 group-hover:opacity-100 duration-700"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="20"
                        viewBox="0 0 10 20"
                        fill="none"
                      >
                        <path
                          d="M1.5 6V5.70758L1.75487 5.56421L9.5 1.20758V0.828182L1 5.30187V14.6981L9.5 19.1718V18.7924L1.75487 14.4358L1.5 14.2924V14V6Z"
                          stroke="#FCFCFC"
                        />
                      </svg>
                    </button>
                    <button
                      {...(disabledBtn && { disabled: disabledBtn })}
                      onClick={handleClickNext}
                      className="bg-btnBg group hover:bg-btnHoverBg duration-700 px-4 py-3 rounded-full cursor-pointer"
                    >
                      <svg
                        className="opacity-40 group-hover:opacity-100 duration-700"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="20"
                        viewBox="0 0 10 20"
                        fill="none"
                      >
                        <path
                          d="M8.5 6V5.70758L8.24513 5.56421L0.5 1.20758V0.828182L9 5.30187V14.6981L0.5 19.1718V18.7924L8.24513 14.4358L8.5 14.2924V14V6Z"
                          stroke="#FCFCFC"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Hero;
