import React from "react";
import { MItem } from "../containers";

// Animation variants
const Left = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "linear" },
  },
};

const Right = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "linear" },
  },
};

const EnergySector = () => {
  return (
    <div className="bg-darkTeal flex justify-center overflow-x-hidden">
      <div className=" py-10 px-4 flex flex-col md:flex-row flex-wrap gap-2.5 sm:px-8 md:px-12 content">
        {/* 1 */}
        <MItem
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.75, once: true }}
          variants={Left}
          queue="01."
          bgColor="bg-blue1"
          textColor="text-white"
          svg={
            <svg
              width="auto"
              height="auto"
              viewBox="0 0 295 295"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="147.383"
                cy="147.382"
                r="68.3911"
                transform="rotate(-45 147.383 147.382)"
                fill="#18978B"
              />
              <mask id="path-2-inside-1_453_1336" fill="white">
                <path d="M221.073 73.6912C230.751 83.3685 238.427 94.857 243.664 107.501C248.902 120.145 251.597 133.697 251.597 147.382C251.597 161.068 248.902 174.62 243.664 187.264C238.427 199.908 230.751 211.396 221.073 221.073C211.396 230.751 199.908 238.427 187.264 243.664C174.62 248.902 161.068 251.597 147.382 251.597C133.697 251.597 120.145 248.902 107.501 243.664C94.8569 238.427 83.3684 230.751 73.6911 221.073L123.032 171.732C126.23 174.93 130.026 177.466 134.204 179.197C138.382 180.928 142.86 181.818 147.382 181.818C151.904 181.818 156.382 180.928 160.56 179.197C164.738 177.466 168.534 174.93 171.732 171.732C174.93 168.535 177.466 164.738 179.197 160.56C180.927 156.382 181.818 151.905 181.818 147.382C181.818 142.86 180.927 138.382 179.197 134.204C177.466 130.026 174.93 126.23 171.732 123.032L221.073 73.6912Z" />
              </mask>
              <path
                d="M221.073 73.6912C230.751 83.3685 238.427 94.857 243.664 107.501C248.902 120.145 251.597 133.697 251.597 147.382C251.597 161.068 248.902 174.62 243.664 187.264C238.427 199.908 230.751 211.396 221.073 221.073C211.396 230.751 199.908 238.427 187.264 243.664C174.62 248.902 161.068 251.597 147.382 251.597C133.697 251.597 120.145 248.902 107.501 243.664C94.8569 238.427 83.3684 230.751 73.6911 221.073L123.032 171.732C126.23 174.93 130.026 177.466 134.204 179.197C138.382 180.928 142.86 181.818 147.382 181.818C151.904 181.818 156.382 180.928 160.56 179.197C164.738 177.466 168.534 174.93 171.732 171.732C174.93 168.535 177.466 164.738 179.197 160.56C180.927 156.382 181.818 151.905 181.818 147.382C181.818 142.86 180.927 138.382 179.197 134.204C177.466 130.026 174.93 126.23 171.732 123.032L221.073 73.6912Z"
                stroke="#FCFCFC"
                stroke-width="2"
                mask="url(#path-2-inside-1_453_1336)"
              />
            </svg>
          }
          title="Inspection and Shutdown support"
          text="We specialize in providing in-service inspections of assets that are non-invasive, non-intrusive and non-destructive."
          stroke="stroke-white"
          svgMargin="-ml-6 -mt-6 sm:-mt-12 sm:mr-6 sm:p-6"
          borderColor="border-teal"
          mdWidth="md:w-49%"
          animate="animated-svg"
          linkTo="/inspection"
        />
        {/* 2 */}
        <MItem
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.75, once: true }}
          variants={Right}
          queue="02."
          bgColor="bg-blue1"
          textColor="text-white"
          svg={
            <svg
              width="auto"
              height="auto"
              viewBox="0 0 299 302"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M218.338 151C218.338 137.187 214.242 123.685 206.568 112.2C198.894 100.715 187.987 91.7641 175.226 86.4782C162.465 81.1923 148.422 79.8093 134.875 82.504C121.328 85.1987 108.884 91.8501 99.1171 101.617C89.3501 111.384 82.6987 123.828 80.004 137.375C77.3093 150.922 78.6923 164.965 83.9782 177.726C89.2641 190.487 98.2154 201.394 109.7 209.068C121.185 216.742 134.687 220.838 148.5 220.838V181.976C142.374 181.976 136.385 180.159 131.291 176.755C126.197 173.352 122.227 168.514 119.882 162.854C117.538 157.194 116.924 150.966 118.12 144.957C119.315 138.948 122.265 133.429 126.597 129.097C130.929 124.765 136.448 121.815 142.457 120.62C148.466 119.424 154.694 120.038 160.354 122.382C166.014 124.727 170.852 128.697 174.255 133.791C177.659 138.885 179.476 144.874 179.476 151H218.338Z"
                fill="#18978B"
              />
              <mask id="path-2-inside-1_453_1347" fill="white">
                <path d="M148.5 257.42C176.724 257.42 203.792 246.208 223.75 226.25C243.708 206.292 254.92 179.224 254.92 151C254.92 122.776 243.708 95.7075 223.75 75.75C203.792 55.7925 176.724 44.5804 148.5 44.5804L148.5 151L148.5 257.42Z" />
              </mask>
              <path
                d="M148.5 257.42C176.724 257.42 203.792 246.208 223.75 226.25C243.708 206.292 254.92 179.224 254.92 151C254.92 122.776 243.708 95.7075 223.75 75.75C203.792 55.7925 176.724 44.5804 148.5 44.5804L148.5 151L148.5 257.42Z"
                stroke="#FCFCFC"
                stroke-width="2"
                mask="url(#path-2-inside-1_453_1347)"
              />
            </svg>
          }
          title="Fully Engineered Solutions"
          text="We are provider for bespoke engineered leak sealing solutions for mature or aging oil & gas facilities."
          stroke="stroke-white"
          svgMargin="-ml-6 -mt-6 sm:-mt-12 sm:mr-6 sm:p-6"
          borderColor="border-teal"
          mdWidth="md:w-49%"
          animate="animated-svg"
          linkTo="/engeenired"
        />
        {/* 3 */}
        <MItem
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.8, once: true }}
          variants={Left}
          queue="03."
          bgColor="bg-blue1"
          textColor="text-white"
          svg={
            <svg
              width="auto"
              height="auto"
              viewBox="0 0 209 209"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M104.382 35.8238C90.8552 35.8238 77.6325 39.8348 66.3856 47.3497C55.1388 54.8647 46.3729 65.5459 41.1966 78.0427C36.0202 90.5396 34.6659 104.291 37.3047 117.557C39.9436 130.824 46.4572 143.01 56.0219 152.575C65.5866 162.139 77.7727 168.653 91.0393 171.292C104.306 173.931 118.057 172.576 130.554 167.4C143.051 162.224 153.732 153.458 161.247 142.211C168.762 130.964 172.773 117.741 172.773 104.215L104.382 104.215L104.382 35.8238Z"
                fill="#18978B"
              />
              <mask id="path-2-inside-1_404_479" fill="white">
                <path d="M104.382 0.000209068C161.938 0.000206552 208.597 46.6588 208.597 104.215C208.597 161.772 161.938 208.43 104.382 208.43C46.8256 208.43 0.16699 161.772 0.166988 104.215C0.166985 46.6588 46.8256 0.000211584 104.382 0.000209068ZM104.382 138.651C123.4 138.651 138.818 123.234 138.818 104.215C138.818 85.1968 123.4 69.7793 104.382 69.7793C85.3635 69.7793 69.9461 85.1968 69.9461 104.215C69.9461 123.234 85.3635 138.651 104.382 138.651Z" />
              </mask>
              <path
                d="M104.382 0.000209068C161.938 0.000206552 208.597 46.6588 208.597 104.215C208.597 161.772 161.938 208.43 104.382 208.43C46.8256 208.43 0.16699 161.772 0.166988 104.215C0.166985 46.6588 46.8256 0.000211584 104.382 0.000209068ZM104.382 138.651C123.4 138.651 138.818 123.234 138.818 104.215C138.818 85.1968 123.4 69.7793 104.382 69.7793C85.3635 69.7793 69.9461 85.1968 69.9461 104.215C69.9461 123.234 85.3635 138.651 104.382 138.651Z"
                stroke="#FCFCFC"
                stroke-width="2"
                mask="url(#path-2-inside-1_404_479)"
              />
            </svg>
          }
          title="Monitoring Services"
          text="Design and implementation of automated emissions control systems Servicing and maintenance of environmental monitoring systems"
          stroke="stroke-white"
          svgMargin="p-6 -mt-4 -ml-4 sm:p-16 sm:-mb-4 sm:ml-0"
          borderColor="border-teal"
          mdWidth="md:w-49%"
          animate="animated-svg"
          linkTo="/monitoring"
        />
        {/* 4 */}
        <MItem
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.8, once: true }}
          variants={Right}
          queue="04."
          bgColor="bg-blue1"
          textColor="text-white"
          svg={
            <svg
              width="auto"
              height="auto"
              viewBox="0 0 209 209"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.9906 104.215C35.9906 122.353 43.1961 139.749 56.0219 152.575C68.8477 165.4 86.2433 172.606 104.382 172.606C122.52 172.606 139.916 165.4 152.742 152.575C165.567 139.749 172.773 122.353 172.773 104.215L104.382 104.215L35.9906 104.215Z"
                fill="#18978B"
              />
              <mask id="path-2-inside-1_404_490" fill="white">
                <path d="M0.166988 104.215C0.166987 76.5757 11.1467 50.0682 30.6908 30.5241C50.2349 10.98 76.7424 0.000225535 104.382 0.000209068C132.021 0.000192601 158.529 10.9799 178.073 30.524C197.617 50.068 208.597 76.5755 208.597 104.215L173.479 104.215C173.479 85.8894 166.199 68.3144 153.241 55.3562C140.283 42.398 122.708 35.1182 104.382 35.1182C86.0563 35.1182 68.4812 42.3981 55.523 55.3563C42.5648 68.3145 35.285 85.8895 35.285 104.215L0.166988 104.215Z" />
              </mask>
              <path
                d="M0.166988 104.215C0.166987 76.5757 11.1467 50.0682 30.6908 30.5241C50.2349 10.98 76.7424 0.000225535 104.382 0.000209068C132.021 0.000192601 158.529 10.9799 178.073 30.524C197.617 50.068 208.597 76.5755 208.597 104.215L173.479 104.215C173.479 85.8894 166.199 68.3144 153.241 55.3562C140.283 42.398 122.708 35.1182 104.382 35.1182C86.0563 35.1182 68.4812 42.3981 55.523 55.3563C42.5648 68.3145 35.285 85.8895 35.285 104.215L0.166988 104.215Z"
                stroke="#FCFCFC"
                stroke-width="2"
                mask="url(#path-2-inside-1_404_490)"
              />
            </svg>
          }
          title="Procurement Support"
          text="Our customers look up to HANSON as a local procurement integrator of foreign manufactured parts, helping them achieve their local content objectives."
          stroke="stroke-white"
          svgMargin="p-6 -mt-4 -ml-4 sm:p-16 sm:-mb-4 sm:ml-0"
          borderColor="border-teal"
          mdWidth="md:w-49%"
          animate="animated-svg"
          linkTo="/procurement"
        />
      </div>
    </div>
  );
};

export default EnergySector;
