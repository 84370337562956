import { Select, Space } from "antd";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const Form = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };

  const templateParams = {
    name: name,
    email: email,
    company: company,
    message: message,
    solutions: selectedValues,
  };

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .send(
        "service_jdp5eu4",
        "template_p4g61f8",
        templateParams,
        "qxzQdnfXLTLNhrM4S"
      )
      .then(
        function (response: any) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error: any) {
          console.log("FAILED...", error);
        }
      );

    setEmail("");
    setName("");
    setCompany("");
    setMessage("");
    setSelectedValues([]);
  };

  return (
    <>
      <div className="bg-greenGradient text-white px-4 sm:px-8 py-8 sm:pr-64 md:pr-8">
        {/* Head */}
        <div>
          <h1 className="text-3xl">Connect us</h1>
          <p className="opacity-70 mt-5 text-sm pr-12">
            Join our community today and stay up-to-date with the latest
            industry news and trends.
          </p>
        </div>
        {/* Form */}
        <form onSubmit={sendEmail} className="mt-8 space-y-8 flex flex-col">
          <div className="flex flex-col">
            <label className="text-sm" htmlFor="email">
              Your email address
            </label>
            <input
              id="email"
              className="bg-transparent border-b border-borderB py-3 focus:outline-none"
              type="email"
              placeholder="yourname@email.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              className="bg-transparent border-b border-borderB py-3 focus:outline-none"
              type="text"
              placeholder="John Doe"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm" htmlFor="company">
              Company name, Country
            </label>
            <input
              id="company"
              className="bg-transparent border-b border-borderB py-3 focus:outline-none"
              type="text"
              placeholder="company name, Turkey"
              required
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm" htmlFor="company">
              Enquiry type
            </label>
            <Space
              direction="vertical"
              style={{ width: "100%" }}
              className="border-b border-borderB pb-2"
            >
              <Select
                value={selectedValues}
                onChange={handleSelectChange}
                dropdownStyle={{ backgroundColor: "white", borderRadius: "0" }}
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select solution"
                options={[
                  {
                    label: "Energy",
                    options: [
                      {
                        label: "Inspection and Shutdown support",
                        value: "Inspection and Shutdown support",
                      },
                      {
                        label: "Fully Engineered solutions",
                        value: "Fully Engineered solutions",
                      },
                      {
                        label: "Monitoring Services",
                        value: "Monitoring Services",
                      },
                      {
                        label: "Procurement Support",
                        value: "Procurement Support",
                      },
                    ],
                  },
                  {
                    label: "Civil",
                    options: [
                      {
                        label: "Design & Architecture",
                        value: "Design & Architecture",
                      },
                      { label: "Access Systems", value: "Access Systems" },
                      {
                        label: "Facility Management",
                        value: "Facility Management",
                      },
                      {
                        label: "Greenz & Luxuriouz",
                        value: "Greenz & Luxuriouz",
                      },
                      {
                        label: "Product Solutions",
                        value: "Product Solutions",
                      },
                    ],
                  },
                  {
                    label: "Medicz",
                    options: [
                      {
                        label: "Home care products",
                        value: "Home care products",
                      },
                      {
                        label: "Diagnostic products",
                        value: "Diagnostic products",
                      },
                      {
                        label: "Emergency & First Aid",
                        value: "Emergency & First Aid",
                      },
                      {
                        label: "Anesthesia & Respiratory",
                        value: "Anesthesia & Respiratory",
                      },
                      {
                        label: "Orthopedic products",
                        value: "Orthopedic products",
                      },
                      {
                        label: "Wound care products",
                        value: "Wound care products",
                      },
                      {
                        label: "Pressure care products",
                        value: "Pressure care products",
                      },
                      {
                        label: "Medical furniture",
                        value: "Medical furniture",
                      },
                      {
                        label: "Laboratory consumables",
                        value: "Laboratory consumables",
                      },
                    ],
                  },
                ]}
              />
            </Space>
          </div>
          <div className="flex flex-col">
            <label className="text-sm" htmlFor="message">
              Message
            </label>
            <textarea
              id="company"
              className="h-28 bg-transparent border-b border-borderB py-3 focus:outline-none resize-none"
              placeholder="write here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="self-center">
            <button
              onClick={sendEmail}
              type="submit"
              className={` bg-transparent border flex items-center border-white px-5 py-3 rounded-full my-8 group`}
            >
              <p className="font-sans1">Send</p>
              <svg
                className={`ml-8 group-hover:ml-12 duration-500 opacity-100`}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="20"
                viewBox="0 0 10 20"
                fill="none"
                stroke="#FCFCFC"
              >
                <path d="M8.5 6V5.70758L8.24513 5.56421L0.5 1.20758V0.828182L9 5.30187V14.6981L0.5 19.1718V18.7924L8.24513 14.4358L8.5 14.2924V14V6Z" />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
