import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ListSvg, Logo, Logo2, monitoring1, monitoring2 } from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

const arrayList = [
  "Implementation of software and hardware packages for total emissions accounting",
  "Installation of continuous gas chromatographers and optical gas analyzers",
  "Installation of flue gas flow and dust content measurement systems",
  "FEED studies and detailed design of automated monitoring systems",
  "Integration into the state regulatory agency’s system for emissions control of an enterprise",
];

function Monitoring() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-blue1">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-teal stroke-teal`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-teal"
              >
                Energy sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Monitoring services
                </motion.h1>
                <CardImg images={[monitoring1, monitoring2]} />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-28">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Monitoring services
                  </motion.h1>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.4 },
                    }}
                    viewport={{ once: true }}
                    className="text-2xl pr-6 md:pr-16"
                  >
                    Automated emissions control and environmental monitoring
                    systems
                  </motion.h1>
                  <div className="flex flex-col mt-4 space-y-4">
                    <div className="flex space-x-2 items-center">
                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: { duration: 0.25, delay: 0.5 },
                        }}
                        viewport={{ once: true }}
                      >
                        <ListSvg
                          rotate=""
                          size="w-5 h-6"
                          opacity="opacity-50"
                          fill={`fill-teal stroke-teal`}
                        />
                      </motion.div>
                      <motion.p
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: { duration: 0.25, delay: 0.65 },
                        }}
                        viewport={{ once: true }}
                        className="text-sm"
                      >
                        Design and implementation of automated emissions control
                        systems Servicing and maintenance of environmental
                        monitoring systems
                      </motion.p>
                    </div>
                    {arrayList.map((text, key) => (
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.5 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-teal stroke-teal`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.65 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                          key={key}
                        >
                          {text}
                        </motion.p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="space-y-3">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.7 },
                    }}
                    viewport={{ once: true }}
                    className="text-2xl"
                  >
                    Predective maintenance monitoring system
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70 text-sm"
                  >
                    One universal solution to increase asset availability and
                    reduce maintenance costs <br /> We offers much more than
                    just sensors, AsystomPredict is a complete turnkey and
                    autonomous predictive maintenance solution for industrial
                    equipment that allows you to analyze, detect, diagnose,
                    predict, and avoid failures much earlier. Designed for
                    anomaly detection based on machine learning.We provide a
                    universal solution for monitoring machines of different ages
                    and designs, without installation constraints.
                  </motion.p>
                </div>
                <div className="space-y-3">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl"
                  >
                    A comprehensive predictive maintenance solution
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70"
                  >
                    We provide a complete solution for predictive maintenance,
                    from measurement to visualization, including analysis and
                    secure data transmission.
                  </motion.p>
                </div>
                <div className="space-y-3">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl"
                  >
                    Asystomsentinel, multi-sensor devices
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70"
                  >
                    Powerful, autonomous and connected multi-sensor beacons that
                    collect and analyze the operating data of the monitored
                    machines.
                  </motion.p>
                </div>
                <div className="space-y-3">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl"
                  >
                    Asystominfra on cloud or on premise services
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70"
                  >
                    A secured service, powered by Asystom devices and the LoRa
                    network, allows secure storage, transfer and treatment of
                    all your measurements.
                  </motion.p>
                </div>
                <div className="space-y-3">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl"
                  >
                    Asystomadvisor, visualization, diagnostics and alert
                    platform
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70"
                  >
                    An Artificial Intelligence-driven alert automation platform
                    accessible on tablets and computers, allowing you to view
                    all data collected in real-time.
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Monitoring);
