import React from "react";
import { medical2, medical3, medical4, medical5, medical6, medical7, mHospital } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Hospital() {
  return (
    <MediczCard
      images={[mHospital, medical2, medical3, medical4, medical5, medical6, medical7]}
      title="Hospital furniture"
      text="Our portfolio of Medical Furniture includes Hospital Beds, Examination Couches, Gynae Couches, Hospital Trolleys, Crash Cart Trolleys, Linen Trolleys, Anesthesia Trolleys, Emergency Resuscitation Trolleys, Monitor Trolleys, Patient Transfer Trolleys, Beside Lockers, Overbed Table, Bedside Screens, IV Poles, Baby Cradle, Surgeon Stools and Footsteps. Our Affiliates warrant availability of products with high end Features."
    />
  );
}

export default transition(Hospital);
