import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ListSvg, Logo, Logo2, design, design1, design2 } from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

const arrayList = [
  "Interior design",
  "Exterior design",
  "Landshaft design",
  "Architecture",
  "Schematic design",
  "Turnkey repair",
  "Author's supervision",
  "Exhibition stands",
  "Restaurants and Offices",
];

function Design() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-teal">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
              <ListSvg
                rotate=""
                size="w-6 h-6"
                opacity="opacity-50"
                fill={`fill-white stroke-white`}
              />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-white"
              >
                Civil sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Design & Architecture
                </motion.h1>
                <CardImg images={[design, design1, design2]} />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Design & Architecture
                  </motion.h1>
                  <div>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.4 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      By developing a personal vision for every project, setting
                      the most unpromising standarts and working side by side
                      with our clients to bring that vision to life. It's an
                      approach that has cemented our reputation as world-class
                      designers of luxury hotels, resorts and private
                      residences.
                    </motion.p>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.5 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl mt-6"
                    >
                      We provide a full range of interior design services and
                      prefer to be included in the project team from the
                      earliest stage.
                    </motion.h1>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1, transition: { delay: 0.6 } }}
                      viewport={{ once: true }}
                      className="flex flex-col space-y-1 mt-6"
                    >
                      {arrayList.map((text, key) => (
                        <div className="flex space-x-2">
                          <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: { duration: 0.25, delay: 0.7 },
                            }}
                            viewport={{ once: true }}
                          >
                            <ListSvg
                              rotate=""
                              size="w-3 h-6"
                              opacity="opacity-50"
                              fill={`fill-blue1 stroke-blue1`}
                            />
                          </motion.div>
                          <motion.p
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: { duration: 0.25, delay: 0.85 },
                            }}
                            viewport={{ once: true }}
                            className="text-sm"
                            key={key}
                          >
                            {text}
                          </motion.p>
                        </div>
                      ))}
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Design);