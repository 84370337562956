import { motion } from "framer-motion";
import React from "react";
import { aHero, aHero1, Logo2 } from "../assets";
import { Navbar } from "../containers";

const AHero = () => {
  return (
    <div className="flex justify-center">
      <div id="hero" className="flex flex-col items-center content">
        <div className={`w-full`}>
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </div>
        <div>
          {/* Content */}
          <div className="text-blue1 flex flex-col md:flex-row space-y-4 sm:space-y-10 md:space-y-0 justify-between mt-20 px-4 sm:px-8 md:px-12 sm:w-3/5 md:w-full">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.3 },
              }}
              viewport={{ once: true }}
              className="text-4xl font-sans1 sm:text-6xl md:w-1/2 md:leading-snug"
            >
              About Hanson International
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.5 },
              }}
              viewport={{ once: true }}
              className="text-darkBrown1 md:w-1/2 md:pr-80"
            >
              HANSON International carries out its activities from a
              professional and independent standpoint, with a strong commitment
              to innovation, sustainable development and respect for the
              environment.
            </motion.p>
          </div>
          {/* Img */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3, delay: 0.7 },
            }}
            viewport={{ once: true }}
            className="md:px-12 mt-8 sm:mt-16"
          >
            <img className="m-0 hidden md:block" src={aHero} alt="" />
            <img className="m-0 w-full md:hidden" src={aHero1} alt="" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AHero;
