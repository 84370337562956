import { motion } from "framer-motion";
import React from "react";
import { ListSvg } from "../assets";
import { MTitleText } from "../containers";

// Animation variants
const textAnimation = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.3, ease: "linear" },
  }),
};

const Welcome = () => {
  return (
    <div className="flex flex-col items-center bg-darkTeal text-white mt-10 sm:mt-16">
      <div className="content">
        <div
          id="welcome"
          className="relative flex md:flex-col px-4 py-12 sm:py-24 sm:px-8 md:px-12 md:py-32 md:pb-56"
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.6, once: true }}
            className="sm: w-3/4 md:w-full flex flex-col md:flex-row space-y-6 sm:space-y-8 md:space-y-0 pr-12 md:pr-0"
          >
            <div className="md:w-1/2 relative">
              <motion.h1
                custom={1}
                variants={textAnimation}
                className="text-2xl leading-snug md:leading-tight sm:text-4.5xl text-teal font-sans1 md:pr-40"
              >
                Welcome to the <br /> new way of solutions for industrial
                sectors.
              </motion.h1>
              <div className="rectangleTeal absolute right-0 top-0 sm:hidden"></div>
            </div>
            <motion.div
              custom={2}
              variants={textAnimation}
              className="md:w-1/2"
            >
              <p className="opacity-50 md:w-3/5">
                HANSON International carries out its activities from a
                professional and independent standpoint, with a strong
                commitment to innovation.
              </p>
            </motion.div>
          </motion.div>
          {/* Gradient bg */}
          <div className="rectangleTeal absolute right-20 top-24 md:hidden"></div>

          {/* Button */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.6, once: true }}
            className="relative w-1/4 md:w-1/2 md:self-end hidden sm:block"
          >
            <div className="rectangleTeal hidden md:block absolute"></div>
            <motion.a
              custom={3}
              variants={textAnimation}
              href="#innovation"
              className="absolute top-0 right-0 md:right-auto  md:left-0 bg-transparent border border-[#00617d4d] rounded-full px-4 py-3 pointer group"
            >
              <div className="flex flex-col -space-y-2 mt-6 group-hover:mt-8 duration-500">
                <ListSvg
                  rotate="rotate-90"
                  opacity="opacity-20"
                  width="w-2.5"
                  height="h-5"
                  fill="fill-white stroke-white"
                />
                <ListSvg
                  rotate="rotate-90"
                  opacity="opacity-50"
                  width="w-2.5"
                  height="h-5"
                  fill="fill-white stroke-white"
                />
                <ListSvg
                  rotate="rotate-90"
                  opacity="opacity-100"
                  width="w-2.5"
                  height="h-5"
                  fill="fill-white stroke-white"
                />
              </div>
            </motion.a>
          </motion.div>
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.7, once: true }}
          id="energySector"
          className="content px-4 sm:px-8 md:px-12 pb-10 md:pb-16 pr-14 sm:pr-0"
        >
          <MTitleText
            custom={1}
            variants={textAnimation}
            display="flex flex-col space-y-4 sm:space-y-6 md:space-y-0 md:flex-row"
            textWidth="md:w-4/5"
            title="ENERGY SECTOR"
            text="We provide cutting edge eco-efficient technology and services to the Marine, Energy, Petroleum, and Industrial sectors. We have an extensive industry network, broad transaction experience supported by a strong track record, and close partnerships with International energy service providers."
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Welcome;
