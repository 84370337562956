import { motion } from "framer-motion";
import React from "react";
import { Logo2, medicz1, mediczMD, mediczSM } from "../assets";
import {  MItem, Navbar } from "../containers";

// Animation variants
const Left = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "linear" },
  },
};

const Right = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "linear" },
  },
};

const PMedicz = () => {
  return (
    <div className="bg-lightBlue flex justify-center">
      <div id="medicz" className="content w-full">
        {/* Nav */}
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo2 />}
          hamburgerSpan="bg-blue1"
          hoverSpanBg="border-blue1"
          mobileBg="bg-white"
          textColor="text-blue1"
          hoverBg="hover:bg-blue1"
          borderColor="border-blue1"
          hoverTextColor="hover:text-white"
        />
        {/* Hero */}
        <div className="text-blue1 mt-12 md:mt-24 px-4 sm:px-8 md:px-12 flex flex-col items-center">
          {/* Content */}
          <div className="flex flex-col sm:flex-row">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3, delay: 0.3 },
              }}
              viewport={{ once: true }}
              className="text-4xl sm:text-6xl font-sans1 w-1/2 sm:w-2/5 md:w-1/2 md:pr-80"
            >
              MEDICZ
            </motion.h1>
            <div className="space-y-4 mt-6 sm:mt-0 sm:w-3/5 md:w-1/2">
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.3, delay: 0.5 },
                }}
                viewport={{ once: true }}
                className="md:pr-32"
              >
                We have a motto for the way we do business at HANSON: Innovation
                connects people. Our goal is to bring together our high
                standards regarding quality, technology and efficiency with
                every person involved in a project – whether a builder or a
                user. After all, we build with and for people. We believe that
                responsibility toward the environment and society is a key
                factor driving the long-term success of companies.
              </motion.p>
            </div>
          </div>
          {/* Img */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3, delay: 0.7 },
            }}
            viewport={{ once: true }}
            className="w-full mt-8 sm:mt-12 md:mt-24"
          >
            <img className="w-full sm:hidden" src={medicz1} alt="" />
            <img
              className="w-full hidden sm:block md:hidden"
              src={mediczSM}
              alt=""
            />
            <img className="w-full hidden md:block" src={mediczMD} alt="" />
          </motion.div>
        </div>
        {/* Content */}
        <div className="flex flex-col md:flex-row gap-2.5 flex-wrap px-4 sm:px-8 md:px-12 py-12 md:py-20">
          {/* 1 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Left}
            queue="01."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 117 132"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M117 132L1.52588e-05 132L6.20223e-06 28.4051L58.5 -1.01446e-05L117 28.4051L117 132Z"
                  fill="#18978B"
                />
                <rect
                  x="45.3057"
                  y="41"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="93"
                  y="62.7227"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 93 62.7227)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Home care products"
            text="Homecare Equipment has a monumental role in helping people perform daily tasks on their own."
            stroke="stroke-blue1"
            svgMargin="p-8 -mt-6 sm:-mt-12 sm:p-20"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/homecare"
          />
          {/* 2 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Right}
            queue="02."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 194 167"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M97 0L193.129 166.5H0.871178L97 0Z" fill="#18978B" />
                <rect
                  x="83.3047"
                  y="72"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="131"
                  y="93.7222"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 131 93.7222)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Diagnostic products"
            text="Diagnostic medical equipment helps clinicians to measure and observe various aspects of a patient's health."
            stroke="stroke-blue1"
            svgMargin="p-4 sm:p-14"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/diognostic"
          />
          {/* 3 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Left}
            queue="03."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 161 139"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M148.585 12.2886C144.651 8.39276 139.981 5.3023 134.84 3.19378C129.699 1.08526 124.189 0 118.624 0C113.059 0 107.549 1.08526 102.408 3.19378C97.2671 5.3023 92.5962 8.39276 88.6623 12.2886L80.4979 20.3701L72.3336 12.2886C64.3873 4.42296 53.6098 0.00407704 42.372 0.00407712C31.1343 0.00407721 20.3568 4.42296 12.4105 12.2886C4.46419 20.1543 8.37279e-08 30.8224 0 41.9461C-8.37279e-08 53.0698 4.46419 63.7379 12.4105 71.6036L20.5748 79.685L80.4979 139L140.421 79.685L148.585 71.6036C152.521 67.7095 155.643 63.0861 157.773 57.9974C159.904 52.9087 161 47.4544 161 41.9461C161 36.4378 159.904 30.9835 157.773 25.8948C155.643 20.8061 152.521 16.1826 148.585 12.2886Z"
                  fill="#18978B"
                />
                <rect
                  x="67.3057"
                  y="32.2432"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="115"
                  y="53.9648"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 115 53.9648)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Emergency & First Aid"
            text="We provide all the essentials for Basic and Emergent Medical Care."
            stroke="stroke-blue1"
            svgMargin="p-4 sm:p-14"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/emergency"
          />
          {/* 4*/}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Right}
            queue="04."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 148 152"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="40.6113"
                  width="54.7778"
                  height="140.722"
                  fill="#18978B"
                />
                <rect
                  x="136"
                  y="43.4443"
                  width="54.7778"
                  height="136"
                  transform="rotate(90 136 43.4443)"
                  fill="#18978B"
                />
                <rect
                  x="87.3965"
                  y="64"
                  width="34.255"
                  height="88"
                  fill="#FCFCFC"
                />
                <rect
                  x="147.047"
                  y="91.168"
                  width="34.255"
                  height="85.047"
                  transform="rotate(90 147.047 91.168)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Anesthesia & Respiratory"
            text="We are well resourced to meet the latest developments in the Medical Field."
            stroke="stroke-blue1"
            svgMargin="p-6 -mt-4 sm:-mt-8 sm:p-16"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/anestesia"
          />
          {/* 5 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Left}
            queue="05."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 153 153"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="76.5" cy="76.5" r="76.5" fill="#18978B" />
                <rect
                  x="63.3057"
                  y="41.2432"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="111"
                  y="62.9648"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 111 62.9648)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Orthopedic products"
            text="Our Orthopedic line of products includes Quality & Innovative Rehabilitation products"
            stroke="stroke-blue1"
            svgMargin="p-4 sm:p-16 sm:-mt-8"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/orthopedic"
          />
          {/* 6 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Right}
            queue="06."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 131 131"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.406492 108.594C7.51017 115.697 15.9435 121.332 25.2249 125.177C34.5063 129.021 44.454 131 54.5002 131C64.5463 131 74.494 129.021 83.7754 125.177C93.0568 121.332 101.49 115.697 108.594 108.594C115.698 101.49 121.332 93.0567 125.177 83.7753C129.021 74.4939 131 64.5461 131 54.5C131 44.4539 129.021 34.5061 125.177 25.2247C121.332 15.9433 115.698 7.51001 108.594 0.406322L54.5002 54.5L0.406492 108.594Z"
                  fill="#18978B"
                />
                <rect
                  x="41.3057"
                  y="20"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="89"
                  y="41.7217"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 89 41.7217)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Wound care products"
            text="We primarily aim at ensuring timely wound healing and effective Infection Control."
            stroke="stroke-blue1"
            svgMargin="p-6 sm:p-16 sm:-mt-8"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/wound"
          />
          {/* 7 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Left}
            queue="07."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 129 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="42.1484"
                  y="0.703125"
                  width="45.9802"
                  height="93.2376"
                  fill="#18978B"
                />
                <path
                  d="M64.5 169.297L8.64138 88.8315L120.359 88.8315L64.5 169.297Z"
                  fill="#18978B"
                />
                <rect
                  x="81.3057"
                  y="99"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="129"
                  y="120.722"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 129 120.722)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Pressure care products"
            text="We primarily aim at ensuring timely wound healing and effective Infection Control."
            stroke="stroke-blue1"
            svgMargin="p-10 -mt-6 sm:-mt-12 sm:p-20"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/pressure"
          />
          {/* 8 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Right}
            queue="08."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 132 162"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="162" fill="#18978B" />
                <rect x="96" y="83" width="36" height="79" fill="#18978B" />
                <rect
                  x="104"
                  y="83"
                  width="36"
                  height="79"
                  transform="rotate(90 104 83)"
                  fill="#18978B"
                />
                <rect
                  x="56.3057"
                  y="35"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="104"
                  y="56.7217"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 104 56.7217)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Medical furniture"
            text="Our portfolio of Medical Furniture includes Hospital Beds, Examination Couches, Gynae Couches and more."
            stroke="stroke-blue1"
            svgMargin="p-10 -mt-6 sm:-mt-12 sm:p-20"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/hospital"
          />
          {/* 9 */}
          <MItem
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.75, once: true }}
            variants={Left}
            queue="09."
            bgColor="bg-lightTeal"
            textColor="text-blue1"
            svg={
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 131 171"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H61V118.5C61 135.345 47.3447 149 30.5 149C13.6553 149 0 135.345 0 118.5V0Z"
                  fill="#18978B"
                />
                <path
                  d="M131 171L70 171L70 52.5C70 35.6553 83.6553 22 100.5 22C117.345 22 131 35.6553 131 52.5L131 171Z"
                  fill="#18978B"
                />
                <rect
                  x="53.3057"
                  y="55"
                  width="27.3889"
                  height="70.3611"
                  fill="#FCFCFC"
                />
                <rect
                  x="101"
                  y="76.7217"
                  width="27.3889"
                  height="68"
                  transform="rotate(90 101 76.7217)"
                  fill="#FCFCFC"
                />
              </svg>
            }
            title="Laboratory consumables"
            text="We can provide you an impressive range of Laboratory Consumables"
            stroke="stroke-blue1"
            svgMargin="p-10 -mt-6 sm:-mt-16 sm:p-20"
            borderColor="border-blue1"
            mdWidth="md:w-49%"
            animate=""
            linkTo="/laboratory"
          />
        </div>
      </div>
    </div>
  );
};

export default PMedicz;
