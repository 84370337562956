import React from "react";
import { mOrthopedic, orthopedic1, orthopedic2, orthopedic3, orthopedic4, orthopedic5 } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Orthopedic() {
  return (
    <MediczCard
      images={[mOrthopedic, orthopedic1, orthopedic2, orthopedic3, orthopedic4, orthopedic5]}
      title="Orthopedic products"
      text="Our Orthopedic line of products includes Quality & Innovative Rehabilitation products, Orthopedic splints and Fracture aids. Our associates comprizes of leading orthopedic manufacturers that offer a comprehensive portfolio. Orthopedic product lines include Collars, Shoulder immobilizers, Arm slings, Back Supports, Finger Splints, Wrist Splints, Ankle Braces & Support, Elbow Supports and Compression Stockings."
    />
  );
}

export default transition(Orthopedic);
