import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  ListSvg,
  Logo,
  Logo2,
  greenz1,
  greenz2,
  greenz4,
  greenz5,
  greenz6,
  greenz3,
} from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

function Greenz() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-teal">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-white stroke-white`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-white"
              >
                Civil sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Greenz & Luxuriouz
                </motion.h1>
                <CardImg
                  images={[greenz1, greenz2, greenz3, greenz4, greenz5, greenz6]}
                />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Greenz & Luxuriouz
                  </motion.h1>
                  <div>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.4 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl"
                    >
                      Bringing Environment friendly and sustainable products
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.5 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      Sustainable interior home designs are growing in demand.
                      People who want to decrease their environmental footprint
                      wants a trendy solution. If you want your home to be
                      eco-friendly and have a stylish design then Bamboo is a
                      fantastic renewable resource to use in your home, but its
                      sustainability is not the only reason the material is so
                      popular. Bamboo is aesthetically pleasing and has a lovely
                      neutral color that can integrate into your home's interior
                      design.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.6 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      We are spearheading a new model of development, putting
                      people and planet first and leveraging the most innovative
                      concepts and technologies to deliver projects that
                      actively enhance the well-being of customers, communities
                      and environments.
                    </motion.p>
                  </div>
                </div>
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.7 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl"
                  >
                    Bamboo – Versatile grass with high strength-to-weight ratio{" "}
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.1 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70 mt-3"
                  >
                    This tropically grown material is a lot like wood, but it’s
                    a much more sustainable option. Bamboo is more similar to
                    grass than it is to trees. It’s also one of the
                    fastest-growing plants in the world, allowing it to be used
                    within four years of planting versus the 60-70 years
                    required by most hardwoods. It also requires very few
                    pesticides or fertilizers, making it even more appealing
                    from an ecological standpoint.
                  </motion.p>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.1 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl mt-10"
                  >
                    Luxuriez
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.1 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70 mt-3"
                  >
                    Taj Mahal, one of the wonders of world. It's known for
                    exceptional inlay work and architecture. HANSON, presents
                    the wonders of same inlay work for your interiors. We
                    specialize in making custom made traditional handicrafts in
                    various sizes, colors and designs. Our inlay handicrafts are
                    crafted by artistic craftsmen, who have inherited their
                    designing skills from their ancestors.
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Greenz);
