import { motion } from "framer-motion";
import React from "react";
interface TitleTextProps {
  title: string;
  text: string;
  display: string;
  textWidth: string;
}

const TitleText = React.forwardRef<HTMLDivElement, TitleTextProps>((props, ref) => (
  <div ref={ref} className={`${props.display} text-white sm:w-2/3 md:w-full`}>
    <div className="md:w-1/2">
      <h1 className="text-2xl sm:text-4.5xl font-sans1 uppercase">
        {props.title}
      </h1>
    </div>
    <div className="md:w-1/2">
      <p className={`opacity-50 text-sm sm:text-base ${props.textWidth}`}>
        {props.text}
      </p>
    </div>
  </div>
));

export const MTitleText = motion(TitleText)