import { motion } from "framer-motion";
import React from "react";
import { innovation1, innovation2, innovation3, TitleIcon } from "../assets";
import { Form } from "../containers";

const Innovation = () => {
  return (
    <div className="bg-white flex justify-center">
      <div
        // ref={targetRef}
        id="innovation"
        className="px-4 sm:px-8 md:px-12 content py-12"
      >
        {/* Header */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.95, once: true }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="flex items-center space-x-4"
        >
          <TitleIcon />
          <h1 className="text-2xl sm:text-3xl font-sans1 text-teal">
            Innovation connects people.
          </h1>
        </motion.div>
        {/* Content */}
        <div className="flex flex-col md:flex-row gap-2 mt-6">
          {/* Form */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.3, ease: "linear" }}
            className="md:w-2/6"
          >
            <Form />
          </motion.div>
          {/* Images */}
          <div className="flex flex-wrap gap-1 sm:gap-2 mt-2 md:mt-0 md:w-4/6">
            <motion.img
              initial={{ opacity: 0, y: -30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.3, delay: 1 }}
              className="w-49% md:flex-0 h-[470px]"
              src={innovation1}
              alt=""
            />
            <motion.img
              initial={{ opacity: 0, y: -30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.3, delay: 1.5 }}
              className="w-49% md:flex-0 h-[470px] object-cover"
              src={innovation2}
              alt=""
            />
            <motion.img
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.3, delay: 1.2 }}
              className="md:flex-grow h-[413px]"
              src={innovation3}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
