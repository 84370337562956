import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  inspection,
  inspection1,
  inspection2,
  inspection3,
  ListSvg,
  Logo,
  Logo2,
} from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

function Inspection() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-blue1">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-teal stroke-teal`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-teal"
              >
                Energy sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-0">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Inspection and Shutdown support
                </motion.h1>
                <CardImg
                  images={[inspection, inspection1, inspection2, inspection3]}
                />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Inspection and Shutdown support
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.4 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70"
                  >
                    We specialize in providing in-service inspections of assets
                    that are non-invasive, non-intrusive and non-destructive.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.5 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70 mt-6"
                  >
                    We provide full external inspection coverage of industry
                    assets to determine degradation and remaining life without
                    needing to resort to costly shutdown, cleaning, internal
                    entry and internal inspection.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.6 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70 mt-6"
                  >
                    It’s about saving costs for our clients, eliminating
                    wasteful periods of production loss and removing risk to
                    personnel during cleaning and entry activities.
                  </motion.p>
                </div>
                <div className="space-y-6">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.7 },
                    }}
                    viewport={{ once: true }}
                    className="text-2xl sm:text-3xl pr-6 md:pr-16"
                  >
                    Quality result through technology & customer collaboration
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="opacity-70"
                  >
                    When innovation is combined with customer collaboration,
                    HANSON is able to present solutions that meet the scope of
                    every project on time and under budget.
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.2 },
                    }}
                    viewport={{ once: true }}
                    className="text-xl"
                  >
                    Empowering Better Consistency and Effectiveness with
                    Integrated Inspection Solutions
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Inspection);
