import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

interface ICardImg {
  images: string[];
}

const CardImg: React.FC<ICardImg> = ({ images }) => {
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const nextIndex = (currentImgIndex + 1) % images.length;
      setCurrentImgIndex(nextIndex);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [currentImgIndex, images]);

  return (
    <div className="flex flex-col space-y-6 z-20">
      {/* Img */}
      <div>
        <AnimatePresence mode="wait">
          <motion.img
            key={currentImgIndex}
            className="w-full h-[280px] sm:h-[440px]"
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -10, opacity: 0 }}
            transition={{ duration: .5, ease: "linear" }}
            src={images[currentImgIndex]}
            alt=""
          />
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CardImg;
