import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { ListSvg, Logo } from "../assets";

interface IFooter {
  goTop: string;
}

const Footer = (props: IFooter) => {
  return (
    <div className="bg-darkTeal flex justify-center">
      <div
        id="footer"
        className="flex flex-col w-full content py-8 px-4 sm:px-8 md:px-12 mb-1"
      >
        {/* Logo */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.9, once: true }}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { duration: 0.3, delay: 0.3 },
            },
          }}
          className="hidden sm:block mb-16 mt-6"
        >
          <Link to="/">
            <Logo />
          </Link>
        </motion.div>
        {/* Content */}
        <div>
          {/* Footer */}
          <div className="text-white flex justify-between md:pb-16">
            {/* Menu */}
            <div className="hidden md:flex flex-col space-y-4 md:w-1/2">
              <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.9, once: true }}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.15 },
                  },
                }}
                className="text-3xl hover:text-teal duration-200"
                href="#energySector"
              >
                <Link to="/energy">Energy</Link>
              </motion.a>
              <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.9, once: true }}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.15 },
                  },
                }}
                className="text-3xl hover:text-teal duration-200"
                href="#civilSector"
              >
                <Link to="/civil">Civil</Link>
              </motion.a>
              <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.9, once: true }}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.15 },
                  },
                }}
                className="text-3xl hover:text-teal duration-200"
                href="#mediczSector"
              >
                <Link to="/medicz">Medicz</Link>
              </motion.a>
              <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.9, once: true }}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.15 },
                  },
                }}
                className="text-3xl hover:text-teal duration-200"
                href="#aboutUs"
              >
                <Link to="/about">About us</Link>
              </motion.a>
            </div>
            {/* About */}
            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-20 md:w-1/2">
              <div className="flex md:flex-col space-x-20 md:space-x-0 md:space-y-20">
                <div>
                  <motion.p
                    custom={1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                    className="mb-2"
                  >
                    Numbers
                  </motion.p>
                  <motion.div
                    custom={2}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                  >
                    <a href="tel:+974 44163604" className="text-sm text-teal">
                      +974 44163604
                    </a>
                    <span className="text-sm text-teal">(Fax)</span>
                  </motion.div>
                  <motion.a
                    custom={3}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                    href="tel:+974 40013017"
                    className="text-sm text-teal"
                  >
                    +974 40013017
                  </motion.a>
                </div>
                <div>
                  <motion.p
                    custom={1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                    className="mb-2"
                  >
                    E-Mail
                  </motion.p>
                  <motion.a
                    href="mailto:info@hansonint.net"
                    custom={2}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                    className="text-sm text-teal"
                  >
                    info@hansonint.net
                  </motion.a>
                </div>
              </div>
              <div className="space-x-20 md:space-x-0 md:space-y-20">
                <div>
                  <motion.p
                    custom={1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                  >
                    Address
                  </motion.p>
                  <motion.p
                    custom={2}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                    className="opacity-50"
                  >
                    P.O Box 35256, New Rayyan,
                    <br /> Shafi Street, Doha, QATAR
                  </motion.p>
                </div>
                <div className="hidden md:flex">
                  <motion.a
                    custom={1}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={footerAnimation}
                    href={`${props.goTop}`}
                    className="bg-transparent border border-[#00617d4d] rounded-full px-4 py-3 pt-4 pointer group"
                  >
                    <div className="flex flex-col -space-y-1">
                      <ListSvg
                        rotate="-rotate-90"
                        opacity="opacity-20"
                        width="w-2.5"
                        height="h-5"
                        fill="fill-white stroke-white"
                      />
                      <ListSvg
                        rotate="-rotate-90"
                        opacity="opacity-50"
                        width="w-2.5"
                        height="h-5"
                        fill="fill-white stroke-white"
                      />
                      <ListSvg
                        rotate="-rotate-90"
                        opacity="opacity-100"
                        width="w-2.5"
                        height="h-5"
                        fill="fill-white stroke-white"
                      />
                    </div>
                  </motion.a>
                </div>
              </div>
            </div>
          </div>
          {/* Smth */}
          <div className="mt-8 border-t pt-5 border-borderB md:justify-between flex flex-col items-center sm:flex-row text-white">
            <motion.p
              initial={{ opacity: 0 }}
              viewport={{ once: true }}
              whileInView={{ opacity: 1, transition: { duration: 1 } }}
              className="text-sm sm:w-1/2"
            >
              <span className="opacity-50">Developed by </span>Caspy Creative
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              viewport={{ once: true }}
              whileInView={{ opacity: 1, transition: { duration: 1 } }}
              className="opacity-50 text-[12px]"
            >
              © 2023. Hanson International - All right reserved.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const footerAnimation = {
  hidden: { opacity: 0, y: -20 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, delay: custom * 0.2 },
  }),
};
