import React from "react";
import { mPressure, pressure1, pressure2, pressure3, pressure4 } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Pressure() {
  return (
    <MediczCard
      images={[mPressure, pressure1, pressure2, pressure3, pressure4]}
      title="Pressure care products"
      text="Pressure or friction skin damage can develop quickly in people with reduced mobility, such as older people or those suffering from disability and confined to a bed or chair. Hanson International has great range of products designed to reduce pressure and increase circulation such as Seat Cushions, Pressure care pads, Foam Mattresses, Air Mattresses, heel protectors, and compression garments."
    />
  );
}

export default transition(Pressure);
