import { AnimatePresence } from "framer-motion";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Access,
  Design,
  Facility,
  Greenz,
  Product,
} from "./components/CivilCards";
import {
  Engeenired,
  Inspection,
  Monitoring,
  Procurement,
} from "./components/EnergyCards";
import {
  Anestesia,
  Diognostic,
  Emergency,
  Homecare,
  Hospital,
  Laboratory,
  Orthopedic,
  Pressure,
  Wound,
} from "./components/MediczCards";
import ScrollTop from "./containers/ScrollTop";
import { About, Civil, Energy, Home, Medicz } from "./pages";

function App() {
  const location = useLocation();

  return (
    <>
        <ScrollTop />
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname} >
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/energy" element={<Energy />} />
            <Route path="/civil" element={<Civil />} />
            <Route path="/medicz" element={<Medicz />} />
            <Route path="/inspection" element={<Inspection />} />
            <Route path="/engeenired" element={<Engeenired />} />
            <Route path="/monitoring" element={<Monitoring />} />
            <Route path="/procurement" element={<Procurement />} />
            <Route path="/facility" element={<Facility />} />
            <Route path="/greenz" element={<Greenz />} />
            <Route path="/design" element={<Design />} />
            <Route path="/product" element={<Product />} />
            <Route path="/access" element={<Access />} />
            <Route path="/homecare" element={<Homecare />} />
            <Route path="/diognostic" element={<Diognostic />} />
            <Route path="/emergency" element={<Emergency />} />
            <Route path="/anestesia" element={<Anestesia />} />
            <Route path="/orthopedic" element={<Orthopedic />} />
            <Route path="/wound" element={<Wound />} />
            <Route path="/pressure" element={<Pressure />} />
            <Route path="/hospital" element={<Hospital />} />
            <Route path="/laboratory" element={<Laboratory />} />
          </Routes>
        </AnimatePresence>
    </>
  );
}

export default App;
