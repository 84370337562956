import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { ListSvg } from "../assets";

interface ItemProps {
  bgColor: string;
  textColor: string;
  queue: string;
  svg: any;
  title: string;
  text: string;
  stroke: string;
  svgMargin: string;
  borderColor: string;
  mdWidth: string;
  animate: string;
  linkTo: string;
}

const Item = React.forwardRef<HTMLDivElement, ItemProps>((props, ref) => (
  <div ref={ref}
    className={`${props.bgColor} ${props.textColor} flex flex-col p-6 ${props.mdWidth}`}
  >
    {/* Queue */}
    <div>
      <div className="flex items-center justify-end sm:justify-start -mr-8 sm:-ml-8">
        <p className="font-sans1 sm:order-2 text-xl">{props.queue}</p>
        <ListSvg
          rotate="rotate-180 sm:rotate-0"
          size="w-6 h-6"
          opacity="opacity-20"
          fill={`fill-white ${props.stroke}`}
        />
      </div>
    </div>
    <div className="flex flex-col sm:flex-row">
      {/* Svg */}
      <div className={`w-1/2 ${props.svgMargin} ${props.animate}`}>
        {props.svg}
      </div>
      {/* Content */}
      <div className=" sm:w-1/2 space-y-6 flex flex-col">
        <h1 className="text-2xl sm:text-3xl font-sans1 pr-24 sm:pr-0">
          {props.title}
        </h1>
        <p className="text-sm opacity-70 pr-8">{props.text}</p>
        {/* Read more btn */}
        <div className="flex justify-center sm:justify-start">
          <Link to={props.linkTo}>
            <button
              className={`flex items-center justify-center sm:w-fit bg-transparent border ${props.borderColor} px-6 py-3 rounded-full mt-16 group`}
            >
              <p className="font-sans1">Read more</p>
              <svg
                className={`ml-6 group-hover:ml-8 duration-300 opacity-100 ${props.stroke}`}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="20"
                viewBox="0 0 10 20"
                fill="none"
              >
                <path d="M8.5 6V5.70758L8.24513 5.56421L0.5 1.20758V0.828182L9 5.30187V14.6981L0.5 19.1718V18.7924L8.24513 14.4358L8.5 14.2924V14V6Z" />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
));

export const MItem = motion(Item)
