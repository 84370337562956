import React from "react";

const TitleIcon = () => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8.33333L9 3V23L0 17.6667V8.33333Z" fill="#18978B" />
      <path d="M18 5.33333L9 0V20L18 14.6667V5.33333Z" fill="#00617D" />
    </svg>
  );
};

export default TitleIcon;