import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  ListSvg,
  Logo,
  Logo2,
  facility,
  facility1,
  facility3,
  facility4,
  facility5,
  facility6,
  facility7,
} from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

const arrayList = [
  "Building Cleaning",
  "Painting Systems",
  "Building Sealing",
  "Facade Maintenance",
  "Epoxy Flooring",
  "Maintenance of HVAC,Garbage chute & MEP works",
  "Excavation and Back Filling",
  "Waterproofing",
  "Carpentry- Wooden cabinets, shelves, wardrobes",
  "Car shades and parking",
  "Steel Fabrication- Fences,gates,handrails,ramp",
  "Gypsum & Glass partitions",
];

function Facility() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-teal">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-white stroke-white`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-white"
              >
                Civil sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Facility Management
                </motion.h1>
                <CardImg
                  images={[
                    facility,
                    facility1,
                    facility3,
                    facility4,
                    facility5,
                    facility6,
                    facility7,
                  ]}
                />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Facility Management
                  </motion.h1>
                  <div>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.4 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl"
                    >
                      Civil Maintenance
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.5 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      Restore your commercial and industrial properties with
                      HANSON’s expertise, from listed buildings to warehouse
                      units.Building maintenance is a very important part of
                      keeping any building in good shape and providing a safe
                      structure for patrons and staff. But high-rise commercial
                      building maintenance comes with height access challenges
                      for most regular tradesmen and building maintenance
                      technicians. Our technicians are up and out there, getting
                      the job done and dusted before you even know it!{" "}
                    </motion.p>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1, transition: { delay: 0.6 } }}
                      viewport={{ once: true }}
                      className="flex flex-col space-y-1 mt-6"
                    >
                      {arrayList.map((text, key) => (
                        <div className="flex space-x-2">
                          <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: { duration: 0.25, delay: 0.7 },
                            }}
                            viewport={{ once: true }}
                          >
                            <ListSvg
                              rotate=""
                              size="w-3 h-6"
                              opacity="opacity-50"
                              fill={`fill-blue1 stroke-blue1`}
                            />
                          </motion.div>
                          <motion.p
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: { duration: 0.25, delay: 0.85 },
                            }}
                            viewport={{ once: true }}
                            className="text-sm"
                            key={key}
                          >
                            {text}
                          </motion.p>
                        </div>
                      ))}
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Facility);
