import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  ListSvg,
  Logo,
  Logo2,
  product,
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
} from "../../assets";
import { Navbar } from "../../containers";
import CardImg from "../../containers/CardImg";
import transition from "../../transition";
import Footer from "../Footer";

const arrayList1 = [
  "Quick and Easy Installations",
  "Solution adapted to your own requirements",
  "Taking care of you and yours",
];

function Product() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-teal">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo />}
          hamburgerSpan="bg-white"
          hoverSpanBg="bg-white"
          mobileBg="bg-darkTeal"
          textColor="text-white"
          hoverBg="hover:bg-white"
          borderColor="border-borderB"
          hoverTextColor="hover:text-black"
        />
        <div id="inspection" className="flex justify-center">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-white stroke-white`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-white"
              >
                Civil sector
              </motion.p>{" "}
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-white text-4xl sm:text-6xl font-sans1 md:hidden sm:pr-10 sm:leading-snug"
                >
                  Product solutions
                </motion.h1>
                <CardImg
                  images={[
                    product,
                    product1,
                    product2,
                    product3,
                    product4,
                    product5,
                    product6,
                    product7,
                  ]}
                />
              </div>
              {/* Content */}
              <div className="flex flex-col text-white space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-white text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    Product solutions
                  </motion.h1>
                  <div>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.4 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      HANSONS Engineering team exists to create extraordinary
                      experiences for clients. We achieve this by developing
                      innovative consumer and commercial products that create
                      social impact, harness the latest technologies and make
                      our clients innovative to the market. Our agile team-based
                      organization is made up of the brightest product
                      designers, engineers and manufacturing specialists around
                      the world. We ensure that each customer is left saying ‘I
                      never thought I could be so satisfied with what HANSON
                      team just did’. We are invested in seeing our customers
                      winning at their goals.
                    </motion.p>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.5 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl mt-6"
                    >
                      Central Vacuum System{" "}
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.6 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      A central vacuum systems comprises vacuum inlet valves
                      strategically located throughout the house, connected by a
                      network of ducting to the central vacuum unit. You simply
                      need to connect the vacuum hose to an inlet valve to begin
                      vacuuming your home quickly, effectively and with a
                      minimum of effort.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.7 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      We offer you one of the most comprehensive ranges of
                      central vacuum units for domestic and commercial
                      applications so you can select the most appropriate system
                      for your home or business premises, no matter what
                      dimensions or particular characteristics the building
                      might have.
                    </motion.p>
                    <div className="flex flex-col space-y-1 mt-6">
                      {arrayList1.map((text, key) => (
                        <div className="flex space-x-2">
                          <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: { duration: 0.25, delay: 0.2 },
                            }}
                            viewport={{ once: true }}
                          >
                            <ListSvg
                              rotate=""
                              size="w-3 h-6"
                              opacity="opacity-50"
                              fill={`fill-blue1 stroke-blue1`}
                            />
                          </motion.div>
                          <motion.p
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: { duration: 0.25, delay: 0.3 },
                            }}
                            viewport={{ once: true }}
                            className="text-sm"
                            key={key}
                          >
                            {text}
                          </motion.p>
                        </div>
                      ))}
                    </div>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      Different clinical studies have proved that the use of a
                      central vacuum system in a home provides benefits with
                      regards to rest and sleep,as well as important benefits
                      from sufferers from allergies or other respiratory
                      ailments
                    </motion.p>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl mt-6"
                    >
                      Internal and External Cladding
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      <span className="font-bold">In the age of clads</span>
                      , HANSON presents a niche segment product for its premium
                      customers to explore a new contemporary style of
                      exteriors. Its excellent technical properties make it
                      suitable for it to be an ideal alternative to traditional
                      materials.
                    </motion.p>
                    <div className="flex flex-col space-y-1 mt-6">
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">10 years Warranty</span> -
                          Ensuring a lifetime of smiles and a commitment of best
                          service
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">
                            All Weather Resistant
                          </span>
                          - Be it scorching summers, frosty winters or wet
                          monsoons
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">No Fading Guarantee</span>
                          - Whatever the conditions are, our claddings never
                          fade
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">No De-Lamination</span>-
                          High quality lamination sticks well to the cladding
                          ensuring everlasting protection
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">Termite Resistant</span>-
                          Termites are a thing of past with our cladding
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">Fire Retardant</span>-
                          You&#39;ll be safe because our cladding is chemically
                          fire retardant
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">Durability</span>- Our
                          cladding is ready for all the wear and tear
                        </motion.p>
                      </div>
                      <div className="flex space-x-2">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.2 },
                          }}
                          viewport={{ once: true }}
                        >
                          <ListSvg
                            rotate=""
                            size="w-3 h-6"
                            opacity="opacity-50"
                            fill={`fill-blue1 stroke-blue1`}
                          />
                        </motion.div>
                        <motion.p
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.25, delay: 0.3 },
                          }}
                          viewport={{ once: true }}
                          className="text-sm"
                        >
                          <span className="font-bold">
                            Size and Thickness Options
                          </span>
                          - Variety of size and thickness options for easy
                          customizations
                        </motion.p>
                      </div>
                    </div>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="text-xl mt-6"
                    >
                      Umbrellas
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      Outdoor umbrellas have a very important function, both at
                      work and for people who spend their free time outside.
                      They provide shade and protection from the sun, rain and
                      other harsh weather conditions. The feature that makes the
                      outdoor umbrella different from the indoor umbrella is
                      that it is resistant to strong winds. This is clear from
                      the inside out or away from the person using it, without
                      fear of being blown away.
                    </motion.p>
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="text-2xl mt-6"
                    >
                      WIDE RANGE OF APPLICATIONS
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.2 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      Market places, Historical and Cultural areas, Public
                      spaces, various event and organization areas, Mosque
                      courtyards, etc. are giant umbrellas that can be applied
                      in a wide range of engineering wonders imaginable.
                    </motion.p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
}

export default transition(Product);
