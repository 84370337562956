import React from "react";
import { anesthesia1, anesthesia2, anesthesia3, anesthesia4, mAnesthesia } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Anestesia() {
  return (
    <MediczCard
      images={[mAnesthesia, anesthesia1, anesthesia2, anesthesia3, anesthesia4]}
      title="Anesthesia & Respiratory"
      text="We offer specially designed devices that ensure precision and quality to meet Consumer needs. We are well resourced to meet the latest developments in the Medical Field. Our Product portfolio includes Face Masks, Nasal Masks, Ventilator Circuits, Catheter Mounts, Patient Circuits, Filters, Oxygen Masks, Nasal Cannulas, Nebulizer Masks, Aircushion Masks, Laryngoscopes, Laryngeal Masks, ET Tubes and Guedel Airways."
    />
  );
}

export default transition(Anestesia);
