import { motion } from "framer-motion";
import React from "react";

const BgSvg = () => {
  return (
    <motion.svg
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ duration: 1.5 }}
      width="200"
      height="621"
      viewBox="0 0 270 621"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.0864 571.233L0.5 619.917V369.434H86.0864V571.233Z"
        stroke="url(#paint0_linear_419_487)"
      />
      <path
        d="M184.845 514.893V206.307V206.009L184.583 205.867L86.8247 152.896L86.0864 152.496V153.336V263.423H0.5V0.86411L269.5 155.484V465.296L184.845 514.893Z"
        stroke="url(#paint1_linear_419_487)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_419_487"
          x1="43.2932"
          y1="368.934"
          x2="43.2932"
          y2="620.776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#18978B" stop-opacity="0.4" />
          <stop offset="1" stop-color="#A5A5A5" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_419_487"
          x1="135"
          y1="0"
          x2="135"
          y2="515.765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#18978B" stop-opacity="0.4" />
          <stop offset="1" stop-color="#A5A5A5" stop-opacity="0" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
};

export default BgSvg;