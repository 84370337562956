import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { medicz } from "../assets";
import { FlotaingCards, MTitleText } from "../containers";

const MediczSector = () => {
  return (
    <div className="bg-darkTeal flex justify-center -mt-2">
      <div className="flex flex-col md:flex-row relative py-12 md:py-24 sm:py-8 px-4 sm:px-8 md:px-12 space-y-16 md:space-y-0 content mt-10">
        <div className="md:w-1/2 sticky md:self-start md:top-24">
          <MTitleText
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.85, once: true }}
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            display="flex flex-col space-y-4 sm:space-y-6"
            textWidth=""
            title="MEDICZ"
            text="Medicz provides the technology and solutions that support the logistics, contracts and processes behind the quality medical supplies and healthcare equipment that you need."
          />
          <motion.button
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.85, once: true }}
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            className="justify-between items-center bg-teal px-6 py-3 rounded-full group hidden md:flex mt-20"
          >
            <p className="font-sans1 text-white">
              <Link to="/medicz">Go to Medicz</Link>
            </p>
            <svg
              className="ml-4 opacity-40 group-hover:ml-8 group-hover:opacity-100 duration-700"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="20"
              viewBox="0 0 10 20"
              fill="none"
            >
              <path
                d="M8.5 6V5.70758L8.24513 5.56421L0.5 1.20758V0.828182L9 5.30187V14.6981L0.5 19.1718V18.7924L8.24513 14.4358L8.5 14.2924V14V6Z"
                stroke="#FCFCFC"
              />
            </svg>
          </motion.button>
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.85, once: true }}
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            className="mt-24 hidden md:inline-block w-[505px]"
            src={medicz}
            alt=""
          />
        </div>
        <div className="md:w-1/2">
          <FlotaingCards />
        </div>
      </div>
    </div>
  );
};

export default MediczSector;
