import React from "react";
import { laboratory1, laboratory2, laboratory3, laboratory4, laboratory5, laboratory6, mLaboratory } from "../../assets";
import { MediczCard } from "../../containers";
import transition from "../../transition";

function Laboratory() {
  return (
    <MediczCard
      images={[mLaboratory, laboratory1, laboratory2, laboratory3, laboratory4, laboratory5, laboratory6]}
      title="Laboratory consumables"
      text="We can provide you an impressive range of Laboratory Consumables. Our Portfolio includes commonly required Lab Consumables such as Specimen Containers, Tongue Depressor, Test Tubes, Conical Tubes, Safety Box, Kidney Dish, Micropipette Tips, Microscope Slides, Swab with and without Media and Torniquet."
    />
  );
}

export default transition(Laboratory);
