import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ListSvg, Logo2 } from "../assets";
import { Footer } from "../components";
import Navbar from "./Navbar";
interface IMediczCard {
  images: string[];
  title: string;
  text: string;
}

const MediczCard: React.FC<IMediczCard> = ({ images, title, text }) => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const nextIndex = (currentImgIndex + 1) % images.length;
      setCurrentImgIndex(nextIndex);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [currentImgIndex, images]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const threshold = 680;

      setIsNavbarFixed(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-lightBlue">
        <Navbar
          navbarBg="bg-transparent"
          navbarBorder=""
          logo={<Logo2 />}
          hamburgerSpan="bg-blue1"
          hoverSpanBg="border-blue1"
          mobileBg="bg-white"
          textColor="text-blue1"
          hoverBg="hover:bg-blue1"
          borderColor="border-blue1"
          hoverTextColor="hover:text-white"
        />
        <div id="inspection" className="flex justify-center h-screen">
          <div className="flex flex-col content px-4 sm:px-8 md:px-12 my-12">
            {/* Section name */}
            <div className="flex items-center space-x-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25 },
                }}
                viewport={{ once: true }}
              >
                <ListSvg
                  rotate=""
                  size="w-6 h-6"
                  opacity="opacity-50"
                  fill={`fill-blue1 stroke-blue1`}
                />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, delay: 0.2 },
                }}
                viewport={{ once: true }}
                className="text-sm text-blue1"
              >
                Medicz sector
              </motion.p>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              {/* CardImg */}
              <div className="space-y-5 md:order-2 md:w-1/2 md:self-start md:sticky md:top-4">
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.25, delay: 0.3 },
                  }}
                  viewport={{ once: true }}
                  className="text-blue1 text-4xl sm:text-6xl font-sans1 md:hidden sm:leading-snug"
                >
                  {title}
                </motion.h1>
                <div className="flex flex-col space-y-6 z-20">
                  {/* Img */}
                  <div>
                    <AnimatePresence mode="wait">
                      <motion.img
                        key={currentImgIndex}
                        className="w-full h-[280px] sm:h-[440px]"
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                        transition={{ duration: 0.5 }}
                        src={images[currentImgIndex]}
                        alt=""
                      />
                    </AnimatePresence>
                  </div>
                </div>
              </div>
              {/* Content */}
              <div className="flex flex-col text-blue1 space-y-10 mt-2 sm:w-2/3 md:w-1/2 md:pr-40">
                <div>
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.25, delay: 0.3 },
                    }}
                    viewport={{ once: true }}
                    className="hidden text-blue1 text-4xl sm:text-6xl font-sans1 md:block md:leading-tight md:mb-6"
                  >
                    {title}
                  </motion.h1>
                  <div>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.25, delay: 0.45 },
                      }}
                      viewport={{ once: true }}
                      className="opacity-70 mt-3"
                    >
                      {text}
                    </motion.p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer goTop="#inspection" />
      </div>

      {isNavbarFixed && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className={`fixed top-0 left-0 right-0`}
        >
          <Navbar
            navbarBg="bg-white"
            navbarBorder="border-b border-blue1 md:border-none"
            logo={<Logo2 />}
            hamburgerSpan="bg-blue1"
            hoverSpanBg="border-blue1"
            mobileBg="bg-white"
            textColor="text-blue1"
            hoverBg="hover:bg-blue1"
            borderColor="border-blue1"
            hoverTextColor="hover:text-white"
          />
        </motion.div>
      )}
    </>
  );
};

export default MediczCard;
